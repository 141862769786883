import React, {Component, useMemo, useState} from "react";
import {AgGridReact} from "@ag-grid-community/react";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import SidebarMenu from "../../components/sideBarComponent";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import CustomNameCellEditor from "../../utils/customCellEditor";
import DTPicker from "../../utils/DTPicker";
import {
    loadDataWithSSEAndStartChangeStreamListener,
    standardHandleInsertEvent,
    standardHandlePopulateGrid,
    standardHandleUpdateAndReplaceEvent
} from "../../utils/sseAndChangeStreamHelper";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {MasterDetailModule} from "@ag-grid-enterprise/master-detail";
import {
    getColumnModeInSession,
    getDefaultAgGridSidebarProps,
    getUseColumnStateInSession,
    onColumnStateChangedHelper,
    onGridReadyHelperForColumnState,
    updateColumnModeInSessionHelper,
    updateUseColumnStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {Switch, ThemeProvider} from "@mui/material";
import {switchTheme} from "../../utils/muiStyling";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {changeCCUserPermission} from "../api/ccOnlyAddToGroupApi";
import {
    ClickToShowColumnOptionsWithToggleButtonGroup,
    customColumnModeText,
    minColumnModeText,
    standardApplyMinimumOrMediumColumnMode
} from "../../components/clickToShowButtons";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";

let gridColumnStateSessionVariableName = "manageCCUsersGridColumnState" //Not saved in user, just in session/local storage
let minColumnIds = ["username", "ccCanCreateLicenses", "ccCanViewDeals", "ccCanViewPartnerGroups", "ccCanAddSelfToGroups", "ccCanViewCompanyLeads"]

export default function CcOnlyManageCCUserPermissions() {
    const [isLoading, setIsLoading] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [sseDataPullActive, setSSEDataPullActive] = useState(true);
    const [asyncTransactionWaitMillis, setAsyncTransactionWaitMillis] = useState(200); //200 to start for the initial sse data pull, will change when sse data pull is done for change streams
    const [enableButtons, setEnableButtons] = useState(false);
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    const [columnMode, setColumnMode] = useState(getColumnModeInSession(gridColumnStateSessionVariableName, minColumnModeText));
    const [columnDefs, setColumnDefs] = useState([
        {
            field: "username", headerName: `Username`, sortable: true, width: 600,
            filter: 'agSetColumnFilter',
            filterParams: {
                suppressSorting: false,
                buttons: ["reset", "apply", "cancel"],
            }
        },
        { field: "ccCanCreateLicenses", headerName: "Can Create Licenses",
            initialWidth: 225,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ["Has Permission","Does Not Have Permission"],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            sortable: true,
            valueGetter: (params) => {
                if(params.node.data.ccCanCreateLicenses){
                    return "Has Permission"
                }
                else{
                    return "Does Not Have Permission"
                }
            },
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <Switch
                                    checked={params.node.data.ccCanCreateLicenses}
                                    name={`cellToggleccCanCreateLicenses${params.node.data.username}`}
                                    onChange={(changeEvent) => {
                                        if(params.node.data.username){
                                            let permission = params.column.colId
                                            let newValue = !params.node.data[params.column.colId]
                                            if(permission !== null && permission !== undefined && newValue !== null && newValue !== undefined){
                                                changeCCUserPermission(params.node.data.username, permission, newValue).then(response => {
                                                    //NotificationManager.success(`Successfully updated this setting`);
                                                    //let change stream handle changing these fields
                                                    params.node.setDataValue(permission, newValue);
                                                }).catch(function(error){
                                                    if(error.message){
                                                        NotificationManager.error(error.message);
                                                    }
                                                    else{
                                                        NotificationManager.error(`Unexpected error making this request`);
                                                    }
                                                })
                                            }
                                        }
                                    }}
                                />
                            </ThemeProvider>
                        </div>
                    )
                }
        },
        { field: "ccCanViewDeals", headerName: "Can View All Partner Deals",
            initialWidth: 275,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ["Has Permission","Does Not Have Permission"],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            sortable: true,
            valueGetter: (params) => {
                if(params.node.data.ccCanViewDeals){
                    return "Has Permission"
                }
                else{
                    return "Does Not Have Permission"
                }
            },
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <Switch
                                    checked={params.node.data.ccCanViewDeals}
                                    name={`cellToggleccCanViewDeals${params.node.data.username}`}
                                    onChange={(changeEvent) => {
                                        if(params.node.data.username){
                                            let permission = params.column.colId
                                            let newValue = !params.node.data[params.column.colId]
                                            if(permission !== null && permission !== undefined && newValue !== null && newValue !== undefined){
                                                changeCCUserPermission(params.node.data.username, permission, newValue).then(response => {
                                                    //NotificationManager.success(`Successfully updated this setting`);
                                                    //let change stream handle changing these fields
                                                    params.node.setDataValue(permission, newValue);
                                                }).catch(function(error){
                                                    if(error.message){
                                                        NotificationManager.error(error.message);
                                                    }
                                                    else{
                                                        NotificationManager.error(`Unexpected error making this request`);
                                                    }
                                                })
                                            }
                                        }
                                    }}
                                />
                            </ThemeProvider>
                        </div>
                    )
                }
        },
        { field: "ccCanEditDeals", headerName: "Can Edit All Partner Deals",
            initialWidth: 275,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ["Has Permission","Does Not Have Permission"],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            sortable: true,
            valueGetter: (params) => {
                if(params.node.data.ccCanEditDeals){
                    return "Has Permission"
                }
                else{
                    return "Does Not Have Permission"
                }
            },
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <Switch
                                    checked={params.node.data.ccCanEditDeals}
                                    name={`cellToggleccCanEditDeals${params.node.data.username}`}
                                    onChange={(changeEvent) => {
                                        if(params.node.data.username){
                                            let permission = params.column.colId
                                            let newValue = !params.node.data[params.column.colId]
                                            if(permission !== null && permission !== undefined && newValue !== null && newValue !== undefined){
                                                changeCCUserPermission(params.node.data.username, permission, newValue).then(response => {
                                                    //NotificationManager.success(`Successfully updated this setting`);
                                                    //let change stream handle changing these fields
                                                    params.node.setDataValue(permission, newValue);
                                                }).catch(function(error){
                                                    if(error.message){
                                                        NotificationManager.error(error.message);
                                                    }
                                                    else{
                                                        NotificationManager.error(`Unexpected error making this request`);
                                                    }
                                                })
                                            }
                                        }
                                    }}
                                />
                            </ThemeProvider>
                        </div>
                    )
                }
        },
        { field: "ccCanViewPartnerGroups", headerName: "Can View Partner Groups",
            initialWidth: 260,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ["Has Permission","Does Not Have Permission"],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            sortable: true,
            valueGetter: (params) => {
                if(params.node.data.ccCanViewPartnerGroups){
                    return "Has Permission"
                }
                else{
                    return "Does Not Have Permission"
                }
            },
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <Switch
                                    checked={params.node.data.ccCanViewPartnerGroups}
                                    name={`cellToggleccCanViewPartnerGroups${params.node.data.username}`}
                                    onChange={(changeEvent) => {
                                        if(params.node.data.username){
                                            let permission = params.column.colId
                                            let newValue = !params.node.data[params.column.colId]
                                            if(permission !== null && permission !== undefined && newValue !== null && newValue !== undefined){
                                                changeCCUserPermission(params.node.data.username, permission, newValue).then(response => {
                                                    //NotificationManager.success(`Successfully updated this setting`);
                                                    //let change stream handle changing these fields
                                                    params.node.setDataValue(permission, newValue);
                                                }).catch(function(error){
                                                    if(error.message){
                                                        NotificationManager.error(error.message);
                                                    }
                                                    else{
                                                        NotificationManager.error(`Unexpected error making this request`);
                                                    }
                                                })
                                            }
                                        }
                                    }}
                                />
                            </ThemeProvider>
                        </div>
                    )
                }
        },
        { field: "ccCanEditPartnerGroups", headerName: "Can Edit Partner Groups",
            initialWidth: 260,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ["Has Permission","Does Not Have Permission"],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            sortable: true,
            valueGetter: (params) => {
                if(params.node.data.ccCanEditPartnerGroups){
                    return "Has Permission"
                }
                else{
                    return "Does Not Have Permission"
                }
            },
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <Switch
                                    checked={params.node.data.ccCanEditPartnerGroups}
                                    name={`cellToggleccCanEditPartnerGroups${params.node.data.username}`}
                                    onChange={(changeEvent) => {
                                        if(params.node.data.username){
                                            let permission = params.column.colId
                                            let newValue = !params.node.data[params.column.colId]
                                            if(permission !== null && permission !== undefined && newValue !== null && newValue !== undefined){
                                                changeCCUserPermission(params.node.data.username, permission, newValue).then(response => {
                                                    //NotificationManager.success(`Successfully updated this setting`);
                                                    //let change stream handle changing these fields
                                                    params.node.setDataValue(permission, newValue);
                                                }).catch(function(error){
                                                    if(error.message){
                                                        NotificationManager.error(error.message);
                                                    }
                                                    else{
                                                        NotificationManager.error(`Unexpected error making this request`);
                                                    }
                                                })
                                            }
                                        }
                                    }}
                                />
                            </ThemeProvider>
                        </div>
                    )
                }
        },
        { field: "ccCanViewAllGroupsAndUsers", headerName: "Can View All Groups and Users",
            initialWidth: 300,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ["Has Permission","Does Not Have Permission"],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            sortable: true,
            valueGetter: (params) => {
                if(params.node.data.ccCanViewAllGroupsAndUsers){
                    return "Has Permission"
                }
                else{
                    return "Does Not Have Permission"
                }
            },
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <Switch
                                    checked={params.node.data.ccCanViewAllGroupsAndUsers}
                                    name={`cellToggleCCCanViewAllGroupsAndUsers${params.node.data.username}`}
                                    onChange={(changeEvent) => {
                                        if(params.node.data.username){
                                            let permission = params.column.colId
                                            let newValue = !params.node.data[params.column.colId]
                                            if(permission !== null && permission !== undefined && newValue !== null && newValue !== undefined){
                                                changeCCUserPermission(params.node.data.username, permission, newValue).then(response => {
                                                    //NotificationManager.success(`Successfully updated this setting`);
                                                    //let change stream handle changing these fields
                                                    params.node.setDataValue(permission, newValue);
                                                }).catch(function(error){
                                                    if(error.message){
                                                        NotificationManager.error(error.message);
                                                    }
                                                    else{
                                                        NotificationManager.error(`Unexpected error making this request`);
                                                    }
                                                })
                                            }
                                        }
                                    }}
                                />
                            </ThemeProvider>
                        </div>
                    )
                }
        },
        { field: "ccCanAddSelfToGroups", headerName: "Can Add Self to Groups",
            initialWidth: 250,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ["Has Permission","Does Not Have Permission"],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            sortable: true,
            valueGetter: (params) => {
                if(params.node.data.ccCanAddSelfToGroups){
                    return "Has Permission"
                }
                else{
                    return "Does Not Have Permission"
                }
            },
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <Switch
                                    checked={params.node.data.ccCanAddSelfToGroups}
                                    name={`cellToggleccCanAddSelfToGroups${params.node.data.username}`}
                                    onChange={(changeEvent) => {
                                        if(params.node.data.username){
                                            let permission = params.column.colId
                                            let newValue = !params.node.data[params.column.colId]
                                            if(permission !== null && permission !== undefined && newValue !== null && newValue !== undefined){
                                                changeCCUserPermission(params.node.data.username, permission, newValue).then(response => {
                                                    //NotificationManager.success(`Successfully updated this setting`);
                                                    //let change stream handle changing these fields
                                                    params.node.setDataValue(permission, newValue);
                                                }).catch(function(error){
                                                    if(error.message){
                                                        NotificationManager.error(error.message);
                                                    }
                                                    else{
                                                        NotificationManager.error(`Unexpected error making this request`);
                                                    }
                                                })
                                            }
                                        }
                                    }}
                                />
                            </ThemeProvider>
                        </div>
                    )
                }
        },
        { field: "internalAllowAdminRole", headerName: "Add Self as Admin to Groups",
            initialWidth: 300,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ["Has Permission","Does Not Have Permission"],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            sortable: true,
            valueGetter: (params) => {
                if(params.node.data.internalAllowAdminRole){
                    return "Has Permission"
                }
                else{
                    return "Does Not Have Permission"
                }
            },
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <Switch
                                    checked={params.node.data.internalAllowAdminRole}
                                    name={`cellToggleinternalAllowAdminRole${params.node.data.username}`}
                                    onChange={(changeEvent) => {
                                        if(params.node.data.username){
                                            let permission = params.column.colId
                                            let newValue = !params.node.data[params.column.colId]
                                            if(permission !== null && permission !== undefined && newValue !== null && newValue !== undefined){
                                                changeCCUserPermission(params.node.data.username, permission, newValue).then(response => {
                                                    //NotificationManager.success(`Successfully updated this setting`);
                                                    //let change stream handle changing these fields
                                                    params.node.setDataValue(permission, newValue);
                                                }).catch(function(error){
                                                    if(error.message){
                                                        NotificationManager.error(error.message);
                                                    }
                                                    else{
                                                        NotificationManager.error(`Unexpected error making this request`);
                                                    }
                                                })
                                            }
                                        }
                                    }}
                                />
                            </ThemeProvider>
                        </div>
                    )
                }
        },
        { field: "ccCanViewCompanyLeads", headerName: "Can Use Org Leads and Phantom Buster Scrapers",
            initialWidth: 450,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ["Has Permission","Does Not Have Permission"],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            sortable: true,
            valueGetter: (params) => {
                if(params.node.data.ccCanViewCompanyLeads){
                    return "Has Permission"
                }
                else{
                    return "Does Not Have Permission"
                }
            },
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <Switch
                                    checked={params.node.data.ccCanViewCompanyLeads}
                                    name={`cellToggleccCanViewCompanyLeads${params.node.data.username}`}
                                    onChange={(changeEvent) => {
                                        if(params.node.data.username){
                                            let permission = params.column.colId
                                            let newValue = !params.node.data[params.column.colId]
                                            if(permission !== null && permission !== undefined && newValue !== null && newValue !== undefined){
                                                changeCCUserPermission(params.node.data.username, permission, newValue).then(response => {
                                                    //NotificationManager.success(`Successfully updated this setting`);
                                                    //let change stream handle changing these fields
                                                    params.node.setDataValue(permission, newValue);
                                                }).catch(function(error){
                                                    if(error.message){
                                                        NotificationManager.error(error.message);
                                                    }
                                                    else{
                                                        NotificationManager.error(`Unexpected error making this request`);
                                                    }
                                                })
                                            }
                                        }
                                    }}
                                />
                            </ThemeProvider>
                        </div>
                    )
                }
        },
        { field: "internalAllowPartnerLeadOwner", headerName: "Allowed to be Org Lead Owner",
            initialWidth: 300,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ["Has Permission","Does Not Have Permission"],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            sortable: true,
            valueGetter: (params) => {
                if(params.node.data.internalAllowPartnerLeadOwner){
                    return "Has Permission"
                }
                else{
                    return "Does Not Have Permission"
                }
            },
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <Switch
                                    checked={params.node.data.internalAllowPartnerLeadOwner}
                                    name={`cellToggleinternalAllowPartnerLeadOwner${params.node.data.username}`}
                                    onChange={(changeEvent) => {
                                        if(params.node.data.username){
                                            let permission = params.column.colId
                                            let newValue = !params.node.data[params.column.colId]
                                            if(permission !== null && permission !== undefined && newValue !== null && newValue !== undefined){
                                                changeCCUserPermission(params.node.data.username, permission, newValue).then(response => {
                                                    //NotificationManager.success(`Successfully updated this setting`);
                                                    //let change stream handle changing these fields
                                                    params.node.setDataValue(permission, newValue);
                                                }).catch(function(error){
                                                    if(error.message){
                                                        NotificationManager.error(error.message);
                                                    }
                                                    else{
                                                        NotificationManager.error(`Unexpected error making this request`);
                                                    }
                                                })
                                            }
                                        }
                                    }}
                                />
                            </ThemeProvider>
                        </div>
                    )
                }
        },
    ])
    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            filterParams: null,
            floatingFilter: true,
            headerClass: "border-0 border-b-0",
            cellClass: "outline:none",
            enableCellChangeFlash: true,
            autoHeight: false,
            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
        }
    )
    const sideBar = useMemo(() => {
        //Inside useMemo to help prevent the sidebar from re-rendering
        return getDefaultAgGridSidebarProps(400)
    }, []);
    const rowSelection = useMemo(() => {
        return {
            mode: 'singleRow',
            enableClickSelection: true,
            checkboxes: false,
            headerCheckbox: false
        };
    }, []);

    return (
        <div className="flex flex-col h-full">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Manage Cyber Crucible Users</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            <div className="flex flex-1 flex-row h-full overflow-y-auto">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="flex flex-1 flex-col flex-nowrap gap-y-3 mt-8 ml-5 mr-10 h-full">
                    {privatePageHeaderHelper("Manage Cyber Crucible Users")}
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-row justify-between flex-wrap gap-x-0 gap-y-3">
                        <div className={"self-end flex flex-col gap-y-3"}>
                            <GridColumnFilterStateSaving
                                useColumnStateSettingToggled={useColumnStateSettingToggled}
                                setUseColumnStateSettingToggled={setUseColumnStateSettingToggled}
                                toggleUpdateUseColumnState={toggleUpdateUseColumnState}/>
                        </div>
                        <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                            {/*<MuiIconButtonWithTooltipAndBox
                                icon={<AddIcon className={"cursor-pointer"}/>} tooltipTitle={"Add yourself to Group(s) with all groups in database shown"}
                                tooltipPlacement={"top"}
                                onClick={() => {
                                    resetAddToGroupModal()
                                    setAddToGroupAllGroupsShownModalIsOpen(true)
                                    //if we have not populated allGroupsList yet then do so now
                                    if(allGroupsList.length === 0) {
                                        listAllGroupsCCOnly().then(response => {
                                            let groupsFormattedForOptions = []
                                            response.forEach(group => {
                                                let objToPush = {}
                                                objToPush.value = group.id
                                                objToPush.label = group.userSetFriendlyName || group.friendlyName || group.id
                                                groupsFormattedForOptions.push(objToPush)
                                            })
                                            groupsFormattedForOptions.sort(function (a, b) {
                                                return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
                                            });
                                            setAllGroupsList(groupsFormattedForOptions)
                                        }).catch(error => {})
                                    }
                                }}/>*/}
                        </div>
                        <div className={"flex flex-row flex-wrap gap-y-3 gap-x-8 self-end justify-end"}>
                            <ClickToShowColumnOptionsWithToggleButtonGroup
                                columnMode={columnMode} setColumnMode={setColumnMode} gridColumnStateSessionVariableName={gridColumnStateSessionVariableName} gridApi={gridApi}
                                minColumnIds={minColumnIds} disableMediumOption={true} updateGridColumnModeFunction={null} />
                            <ClearRefresh gridApi = {gridApi} showRefreshIcon={false} />
                        </div>
                    </div>
                    <div className="h-full flex flex-col gap-y-5" id="gridRoot">
                        <Grid
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            sideBar={sideBar}
                            rowSelection={rowSelection}
                            setGridApi={setGridApi}
                            sseDataPullActive={sseDataPullActive}
                            setEnableButtons={setEnableButtons}
                            setSSEDataPullActive={setSSEDataPullActive}
                            asyncTransactionWaitMillis={asyncTransactionWaitMillis}
                            setAsyncTransactionWaitMillis={setAsyncTransactionWaitMillis}
                            columnMode={columnMode}
                            setColumnMode={setColumnMode}
                        />
                        <Footer />
                    </div>
                </div>
            </div>

            <NotificationContainer />
        </div>
    );
    function toggleUpdateUseColumnState(toggleSetting){
        updateUseColumnStateHelper(toggleSetting, gridColumnStateSessionVariableName, null);
    }
}

class Grid extends Component {
    rowData = []
    updateTransactionsToApply = []
    abortController = new AbortController()

    constructor(props, onClickRow, filterVals) {
        super(props);
    }

    onCellEditingStopped = (event) => {
        let gridApi = event.api
        //Nothing to edit on this grid
    }

    componentWillUnmount(){
        this.abortController.abort()
    }

    populateGrid = async (rowData) => {
        standardHandlePopulateGrid(rowData, this.gridApi)
    }

    updateGridForChangeStream = async (changeStreamData) => {
        let operationType = changeStreamData.operationType
        let objectBody = changeStreamData.body
        if(operationType === "UPDATE" || operationType === "REPLACE"){
            standardHandleUpdateAndReplaceEvent(objectBody, this.gridApi, this.props.sseDataPullActive, this.updateTransactionsToApply)
        }
        else if (operationType === "INSERT"){
            standardHandleInsertEvent(objectBody, this.gridApi, this.props.sseDataPullActive)
        }
    }

    getRowId = (params) => {
        return params.data.username
    }

    getContextMenuItems = (params) => {
        return [
            "resetColumns",
            "autoSizeAll"
        ];
    };

    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        if(params.source !== "api" && params.source !== "gridOptionsChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, null)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, null, customColumnModeText)
        }
        else if(params.source === "api" && params.type === "sortChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, null)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, null, customColumnModeText)
        }

    }

    onGridReady = async (gridReadyParams) => {
        this.gridApi = gridReadyParams.api;
        this.props.setGridApi(gridReadyParams.api);

        // Disable text selection on the page while holding shift or control (to allow grid selections to be done easily without selecting all text)
        ["keyup","keydown"].forEach((event) => {
            window.addEventListener(event, (e) => {
                document.onselectstart = function() {
                    return !(e.shiftKey || e.ctrlKey);
                }
            });
        });

        let columnMode = this.props.columnMode
        //check which initial column mode to apply
        if(columnMode === customColumnModeText){
            onGridReadyHelperForColumnState(gridReadyParams, gridColumnStateSessionVariableName)
        }
        else if(columnMode === minColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, gridReadyParams.api, this.props.setColumnMode, minColumnModeText, minColumnIds, null)
        }
        //Medium not allowed for this grid, only min/max/custom
        //else if columnMode is max then the default column state already shows the max amount of columns no need to update


        await loadDataWithSSEAndStartChangeStreamListener("/ccRetrieveAllCyberCrucibleUsers", "/sse/listenToCCUsersEvent",
            this.populateGrid, this.updateGridForChangeStream, gridReadyParams, this.props.setSSEDataPullActive, this.props.setAsyncTransactionWaitMillis, this.updateTransactionsToApply,
            this.abortController)
    };
    render() {
        return (
            <div className={"w-full h-full"} style={{minHeight: "400px"}}>
                <div id="myGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                    <AgGridReact
                        columnMenu={"legacy"}
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, MasterDetailModule]}
                        defaultColDef={this.props.defaultColDef}
                        columnDefs={this.props.columnDefs}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        multiSortKey={"ctrl"}
                        rowData={this.rowData}
                        onGridReady={this.onGridReady}
                        asyncTransactionWaitMillis={this.props.asyncTransactionWaitMillis}
                        suppressModelUpdateAfterUpdateTransaction={true}
                        getRowId={this.getRowId}
                        maintainColumnOrder={true}
                        onCellEditingStopped={this.onCellEditingStopped}
                        rowSelection={this.props.rowSelection}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            if(selectedRows && selectedRows.length > 0){
                                //checks if the setEnableButtons method is null or not
                                this.props.setEnableButtons && this.props.setEnableButtons(true);
                            }
                            else{
                                this.props.setEnableButtons && this.props.setEnableButtons(false);
                            }
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        getContextMenuItems={this.getContextMenuItems}
                        sideBar={this.props.sideBar}
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}
                    />
                </div>
            </div>
        );
    }
}
