import './App.css';
import {Navigate, Route, Routes} from "react-router-dom";
import PrivacyPolicy from "./pages/static/privacy-policy";
import Terms from "./pages/static/eula";
//import React from "react";
import Agents from "./pages/private/agents";
import Callback from "./pages/private/callback";
import Licenses from "./pages/private/licenses";
import TailoredBehaviors from "./pages/private/tailoredBehaviors";
import ProcessInjections from "./pages/private/processInjections";
import Settings from "./pages/private/settings";
import Deals from "./pages/private/deals";
import Notifications from "./pages/private/notifications";

import Groups from "./pages/private/groups";
import Roles from "./pages/private/roles";
import Incidents from "./pages/private/incidents";
import Login from "./pages/static/login";
import Register from "./pages/static/register";
import ResetPassword from "./pages/static/resetPassword";
import {Amplify} from "aws-amplify";
import ReactGA from 'react-ga';
import Sitemap from "./components/sitemap";
import SalesNotifications from "./pages/private/salesNotifications";
import Tasks from "./pages/private/tasks";
import RestIntegration from "./pages/private/restIntegration";
import SessionExpiration from "./pages/private/sessionExpiration";
import ProcessCreations from "./pages/private/processCreations";
import SilentResponses from "./pages/private/silentResponses";
import Collectipede from "./pages/private/collectipede";
import BrowserDataIncidents, {
    blockedIdentityAccessPage,
    hiddenIdentityAccessPage
} from "./pages/private/browserDataIncidents";
import AgentReleaseEmailSubmissions from "./pages/private/agentReleaseEmailSubmissions";
import TrainingLicenses from "./pages/private/trainingLicenses";
import React from "react";
import {AmplifyAuthStorage} from "./utils/customAwsStorage";
import { library } from '@fortawesome/fontawesome-svg-core'
import {faColumns, faFilterSlash, faRotate, faTrashCan, faUserCog, faWarehouse, faDialMin, faDialMed, faDialMax, faUser,
    faMagnifyingGlassArrowRight, faSyringe, faVolume, faVolumeSlash, faPenToSquare, faUserPlus, faSortAlphaUp, faSortAlphaDownAlt,
    faFilter, faEdit, faShieldSlash, faShieldHalved, faBinoculars, faUserCircle, faDownload, faBadgeCheck, faUserClock, faInfoCircle as faInfoCircleDuoTone,
    faCalendarClock, faClockRotateLeft, faSquareQuote, faFileInvoiceDollar, faSquareDollar, faCalendar, faArrowRotateLeft, faRotateLeft,
    faEject, faIdBadge, faCirclePause, faFileCertificate, faArrowUp, faFlaskGear, faSquareXmark, faRightLeft, faMusicNote, faLocationDot,
    faUpRightFromSquare, faUserTie, faUpload as faUploadDuoTone, faChartNetwork, faUserPen} from "@fortawesome/pro-duotone-svg-icons";
import {faStripeS} from "@fortawesome/free-brands-svg-icons";
import {faCaretDown, faCaretUp, faXmark, faCheck, faCircleMinus, faInfoCircle as faInfoCircleSolid,faTriangleExclamation} from "@fortawesome/pro-solid-svg-icons";
import {faInfoCircle, faSpinner, faBars, faUpload, faChevronLeft} from "@fortawesome/pro-light-svg-icons";
import CcOnlyPartnerGroupManagement from "./pages/private/ccOnlyPartnerGroupManagement";
import PartnerDemoVideos from "./pages/private/partnerDemoVideos";
import PartnerPaymentManagement from "./pages/private/partnerPaymentManagement";
import DistributorGroupManagement from "./pages/private/distributorGroupManagement";
import CcOnlyAddToGroup from "./pages/private/ccOnlyAddToGroup";
import CcOnlyMinidumps from "./pages/private/ccOnlyMinidumps";
import CCOnlyPartnerOrganizationLeads from "./pages/private/partnerOrganizationLeads";
import CustomerDeploymentHealth from "./pages/private/customerDeploymentHealth";
import {
    browserDataIncidentBlocksList,
    browserDataIncidentBlocksPerGroupCountReactive,
    browserDataIncidentHidesList,
    browserDataIncidentHidesPerGroupCountReactive,
    findByBrowserDataIncidentBlocksIdListReactive, findByBrowserDataIncidentHidesIdListReactive
} from "./pages/api/browserDataIncidentsApi";
import CCOnlyGroupIdentityAccessVisibility from "./pages/private/ccOnlyGroupIdentityAccessVisibility";
import TermsOfService from "./pages/static/termsOfService";
import CcOnlyLicenseTrueUps from "./pages/private/ccOnlyLicenseTrueUps";
import CCOnlyLinkedInCompanyProfiles from "./pages/private/ccOnlyLinkedInCompanyProfiles";
import CcOnlyLinkedInEmployeeProfiles from "./pages/private/ccOnlyLinkedInEmployeeProfiles";
import PartnersInMotion from "./pages/private/partnersInMotion";
import CcOnlyManageCCUserPermissions from "./pages/private/ccOnlyManageCCUserPermissions";

library.add(faInfoCircle, faFilterSlash, faColumns, faRotate, faSpinner, faBars, faTrashCan, faInfoCircleDuoTone,
    faUserCog, faWarehouse, faUpload, faChevronLeft, faMagnifyingGlassArrowRight, faSyringe, faInfoCircleSolid,
    faVolume, faVolumeSlash, faPenToSquare, faUserPlus, faCaretUp, faCaretDown, faSortAlphaUp, faSortAlphaDownAlt,
    faFilter, faEdit, faShieldSlash, faShieldHalved, faBinoculars, faUserCircle, faDownload, faArrowUp,
    faDialMin, faDialMed, faDialMax, faUser, faBadgeCheck, faUserClock, faCalendarClock, faCheck,
    faXmark, faCircleMinus, faClockRotateLeft, faSquareQuote, faFileInvoiceDollar, faSquareDollar, faCalendar,
    faArrowRotateLeft, faRotateLeft, faEject, faIdBadge, faCirclePause, faFileCertificate, faFlaskGear,
    faStripeS, faSquareXmark, faRightLeft, faMusicNote, faLocationDot, faUpRightFromSquare, faUserTie, faTriangleExclamation,
    faUploadDuoTone, faChartNetwork, faUserPen);

const trackingCode = process.env.REACT_APP_GA_ID
ReactGA.initialize(trackingCode); //pageviews are updated in header.js


Amplify.configure({
    Auth: {
        region: "us-west-2",
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_APP_CLIENT_ID,
        storage: AmplifyAuthStorage
    }
});

/*
    Note that the mui overlay loading circle for pages should have higher z-index than react-modal, but the react-modal
    should have higher z-index than the sidebarmenu mui drawer, the default mui drawer z-index is 1200.

    The MUI tooltip default z-index is 1500, so we want the react-modal index to be higher than the sidebar MUI drawer, but less
    than the tooltip z-index so tooltips appear on the modal and not behind it.

    In App.css, we will override the ReactModal classes to set z-index to 1300.

    We should convert to using MUI Modal instead of react-modal to avoid this conflict.
 */
export const muiLoadingCircleZIndex = 2000
function App() {
    //Note the 'key' is needed for react to re-render the browser incident pages since we are re-using the BrowserDataIncidents component, else it would just update the url and not go fetch new data or anything
    return (
        <Routes>
            <Route path="/private/browserDataIncidentBlocks" element={
                <BrowserDataIncidents key={"identityBlocks"} identityAccessPage={blockedIdentityAccessPage} pageTitle={"Identity Access Blocks"} gridQueryFunction={browserDataIncidentBlocksList}
                                      chartsApiFunction={browserDataIncidentBlocksPerGroupCountReactive} findByIdApiFunction={findByBrowserDataIncidentBlocksIdListReactive}
                />}/>
            <Route path="/private/browserDataIncidentHides" element={
                <BrowserDataIncidents key={"identityHides"} identityAccessPage={hiddenIdentityAccessPage} pageTitle={"Identity Access Hides"} gridQueryFunction={browserDataIncidentHidesList}
                                      chartsApiFunction={browserDataIncidentHidesPerGroupCountReactive} findByIdApiFunction={findByBrowserDataIncidentHidesIdListReactive}

                />}/>
            <Route path="/private/ccLinkedInEmployeeProfiles" element={<CcOnlyLinkedInEmployeeProfiles/>}/>
            <Route path="/private/ccLinkedInCompanyProfiles" element={<CCOnlyLinkedInCompanyProfiles/>}/>
            <Route path="/private/customerDeploymentHealth" element={<CustomerDeploymentHealth/>}/>
            <Route path="/private/ccLicenseTrueUps" element={<CcOnlyLicenseTrueUps/>}/>
            <Route path="/private/ccPartnerOrganizationLeads" element={<CCOnlyPartnerOrganizationLeads/>}/>
            <Route path="/private/ccGroupIdentityAccessVisibility" element={<CCOnlyGroupIdentityAccessVisibility/>}/>
            <Route path="/private/demoVideos" element={<PartnerDemoVideos/>}/>
            <Route path="/private/trainingLicenses" element={<TrainingLicenses/>}/>
            <Route path="/private/ccMinidumps" element={<CcOnlyMinidumps/>}/>
            <Route path="/private/ccAddToGroup" element={<CcOnlyAddToGroup/>}/>
            <Route path="/private/ccManageCCUserPermissions" element={<CcOnlyManageCCUserPermissions/>}/>
            <Route path="/private/partnerGroupManagement" element={<CcOnlyPartnerGroupManagement/>}/>
            <Route path="/private/distributorGroupManagement" element={<DistributorGroupManagement/>}/>
            <Route path="/private/partnersInMotion" element={<PartnersInMotion/>}/>
            <Route path="/private/agentReleaseEmailSubmissions" element={<AgentReleaseEmailSubmissions/>}/>
            <Route path="/private/paymentManagement" element={<PartnerPaymentManagement/>}/>
            <Route path="/private/collectipede" element={<Collectipede/>}/>
            <Route path="/private/silentResponses" element={<SilentResponses/>}/>
            <Route path="/private/processCreations" element={<ProcessCreations/>}/>
            <Route path="/private/sessionDurations" element={<SessionExpiration/>}/>
            <Route path="/private/restIntegration" element={<RestIntegration/>}/>
            <Route path="/private/incidents" element={<Incidents/>}/>
            <Route path="/private/browserDataIncidents" element={<BrowserDataIncidents key={"identityAccess"}/>}/>
            <Route path="/private/roles" element={<Roles/>}/>
            <Route path="/private/groups" element={<Groups/>}/>
            <Route path="/private/notifications" element={<Notifications/>}/>
            <Route path="/private/salesNotifications" element={<SalesNotifications/>}/>
            <Route path="/private/partnerRegisterDeal" element={<Deals/>}/>
            <Route path="/private/settings" element={<Settings/>}/>
            <Route path="/private/processInjections" element={<ProcessInjections/>}/>
            <Route path="/private/tasks" element={<Tasks/>}/>
            <Route path="/private/tailoredBehaviors" element={<TailoredBehaviors/>}/>
            <Route path="/private/licenses" element={<Licenses/>}/>
            <Route path="/private/agents" element={<Agents/>}/>
            <Route path="/sitemap" element={<Sitemap/>}/>
            <Route path="/callback" element={<Callback/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/createAccount" element={<Register/>}/>
            <Route path="/resetPassword" element={<ResetPassword/>}/>
            <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
            <Route path="/terms-of-use" element={<TermsOfService/>}/>
            <Route path="/eula" element={<Terms/>}/>
            <Route path="/" element={<Navigate to="/login" replace/>}/>
            <Route path="*" element={<Navigate to="/login" replace/>}/>
        </Routes>
    );
}
export default App;
