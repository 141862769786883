import {FormControlLabel, FormGroup, Switch, ThemeProvider} from "@mui/material";
import React from "react";
import {switchTheme} from "../utils/muiStyling";
import {MuiIconWithTooltip} from "./muiComponents";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


export const GridColumnFilterStateSaving = ({useFilterStateSettingToggled, setUseFilterStateSettingToggled, toggleUpdateUseFilterState,
                                                useColumnStateSettingToggled,setUseColumnStateSettingToggled,toggleUpdateUseColumnState,
                                                showAllTelemetryDataToggled,toggleUpdateShowAllTelemetryData
                                            }) => {

    return (
        <div className={`flex flex-row items-center`}>
            <ThemeProvider theme = {switchTheme}>
                <FormGroup>
                    {toggleUpdateUseFilterState !== null && toggleUpdateUseFilterState !== undefined ? (
                        <FormControlLabel control={
                            <Switch
                                checked={useFilterStateSettingToggled}
                                name="useFilterStateSettingToggled"
                                color={"primary"}
                                onChange={toggleSetting => {
                                    setUseFilterStateSettingToggled(toggleSetting.target.checked)
                                    toggleUpdateUseFilterState(toggleSetting.target.checked)
                                }}
                            />
                        } label={useFilterStateSettingToggled ? "Grid Filter Saving Enabled" : "Grid Filter Saving Disabled"}/>
                    ) : ""}
                    <FormControlLabel control={
                        <Switch
                            checked={useColumnStateSettingToggled}
                            name="useColumnStateSettingToggled"
                            color={"primary"}
                            onChange={toggleSetting => {
                                setUseColumnStateSettingToggled(toggleSetting.target.checked)
                                toggleUpdateUseColumnState(toggleSetting.target.checked)
                            }}
                        />
                    } label={useColumnStateSettingToggled ? "Column State Saving Enabled" : "Column State Saving Disabled"}/>
                    {toggleUpdateShowAllTelemetryData !== null && toggleUpdateShowAllTelemetryData !== undefined ?
                        (
                            <div className={"flex flex-row items-center flex-nowrap"}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={showAllTelemetryDataToggled}
                                        name="showAllTelemetryDataToggled"
                                        color={"primary"}
                                        onChange={toggleSetting => {
                                            toggleUpdateShowAllTelemetryData && toggleUpdateShowAllTelemetryData(toggleSetting.target.checked)
                                        }}
                                    />
                                } label={showAllTelemetryDataToggled ? "Showing All Telemetry Data" : "Showing Only Response Related Data"}
                                />
                                <MuiIconWithTooltip
                                    icon={
                                        <FontAwesomeIcon
                                            className="mt-1 mr-1 object-contain cursor-pointer"
                                            icon="fa-light fa-circle-info"
                                            size="lg"
                                        />
                                    }
                                    tooltipTitle={showAllTelemetryDataToggled ?
                                        "You currently are viewing all telemetry data on this grid, toggle to view only extortion response related data"
                                        : "You currently are viewing only extortion response related data on this grid, toggle to view all telemetry data"}
                                    tooltipPlacement={"bottom-start"}
                                />
                            </div>

                        ) : ""}
                </FormGroup>
            </ThemeProvider>
        </div>
    );
};
