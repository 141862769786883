import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {Auth} from "aws-amplify";
import Modal from "react-modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import QRCode from "react-qr-code";
import {initializeGroupsAgentTelemetrySettingReactive, updateAccountInfoReactive} from "../pages/api/loginApi";
import {changeUserTimeZoneReactive} from "../pages/api/settingsApi";
import TimezonePicker from 'react-bootstrap-timezone-picker';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import {encryptAndStoreSessionVariable} from "../utils/storageHelper";
import {buttonTheme, roundButtonTheme, switchTheme} from "../utils/muiStyling";
import {Button, FormControlLabel, IconButton, Switch, ThemeProvider} from "@mui/material";
import {MuiCloseIconButton, MuiIconWithTooltip} from "./muiComponents";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export const NewPasswordModal = ({ onClose, onSuccess, user, opened }) => {
    const { register, handleSubmit, watch, reset } = useForm();
    const [showPassword, setShowPassword] = useState(false);
    const completeNewPassWord = async () => {
        const pass = watch("newPassword");
        const passConfirm = watch("newPasswordConfirm");
        if(pass !== passConfirm){
            NotificationManager.error(
                `Passwords must match.`
            );
            return;
        }
        try {
            await Auth.completeNewPassword(
                user,
                pass
            ).then((newPassUser) => {
                //alert("You have successfully made a new password! You will be asked to login again with your new password.")
                onSuccess();
            }).catch( e => {
                if(e.message){
                    NotificationManager.error(e.message);
                }
                else{
                    NotificationManager.error(
                        `Error setting new password, please try again.`
                    );
                }
            });
        } catch (error) {
            NotificationManager.error(
                `Error setting new password, please try again.`
            );
        }
    };
    return (
        <Modal
            onRequestClose={() => {
                reset({})
                onClose()
            }}
            isOpen={opened}
            contentLabel={"Setup New Password"}
            className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white xl:w-lg lg:w-lg md:w-lg sm:w-lg xs:w-11/12 max-w-lg inset-y-10 mx-auto rounded-2xl overflow-auto`}
            overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
        >
            <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(completeNewPassWord)}>
                <div className="flex flex-1 flex-col gap-y-3 w-full">
                    {/*Title with exit button*/}
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">Setup New Password</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                reset({})
                                onClose()
                            }}
                        />
                    </div>
                    <hr className="mt-3 h-0.5"/>
                    {/*Form content*/}
                    <label className="ml-1 ">A new password is required, please enter your new password below:</label>
                    <div className="ml-1 w-full">
                        <small>Password</small>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="off"
                            required
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                            name="newPassword"
                            {...register("newPassword")}
                            className="focus:outline-none h-10 p-2 w-full mt-2 rounded-lg border border-black border-opacity-25 border-solid pr-10"
                        />
                        <span className="passwordIcon">
                                            {showPassword
                                                ?
                                                <IconButton sx={{width: 25, height: 25}}
                                                            className={`self-center object-contain`}>
                                                    <Visibility className={"cursor-pointer"} onClick={() => {
                                                        setShowPassword(!showPassword)
                                                    }}/>
                                                </IconButton>
                                                :
                                                <IconButton sx={{width: 25, height: 25}}
                                                            className={`self-center object-contain`}>
                                                    <VisibilityOff className={"cursor-pointer"} onClick={() => {
                                                        setShowPassword(!showPassword)
                                                    }}/>
                                                </IconButton>
                                            }
                                        </span>
                    </div>
                    <div className="ml-1 mb-1 w-full">
                        <small>Confirm Password</small>
                        <br/>
                        <input
                            name="newPasswordConfirm"
                            type={showPassword ? 'text' : 'password'}
                            required
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                            {...register("newPasswordConfirm")}
                            className="focus:outline-none h-10 p-2 w-full mt-2 rounded-lg border border-black border-opacity-25 border-solid"
                        />
                    </div>
                    <ThemeProvider theme={roundButtonTheme}>
                        <Button variant={"contained"} color={"primary"} type={"submit"}>
                            Submit New Password
                        </Button>
                    </ThemeProvider>
                </div>

            </form>
        </Modal>
    );
};

export const MFAModal = ({onClose, onSuccess, isSetup, user, opened, setIsLoadingMethod}) => {
    const {register, handleSubmit} = useForm();
    const [qrCode, setQrCode] = useState();

    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            if (isSetup && user) {
                try {
                    const qrCode = await Auth.setupTOTP(user);
                    const generatedQrCode =
                        "otpauth://totp/" + user.username + "?secret=" + qrCode + "&issuer=Cyber%20Crucible";
                    setQrCode(generatedQrCode);
                } catch (error) {
                    console.log(error)
                }
            }
        })()
        return () => controller?.abort();
    }, [isSetup, user]);

    const verifyTOTPCode = async (data) => {
        setIsLoadingMethod(true)
        try {
            const totpCode = data.mfa
            const auth = qrCode
                ? await Auth.verifyTotpToken(user, totpCode)
                : await Auth.confirmSignIn(user, totpCode, "SOFTWARE_TOKEN_MFA");
            if (auth) {
                onSuccess();
            }
        } catch (error) {
            if (error.message) {
                NotificationManager.error(error.message)
            } else {
                NotificationManager.error("Wrong code, please try again.")
            }
            setIsLoadingMethod(false)
        }
    };

    return (
        <Modal
            onRequestClose={() => {
                onClose()
            }}
            isOpen={opened}
            contentLabel={isSetup ? "Setup your MFA" : "Enter your MFA"}
            className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white ${isSetup ? "max-w-xl" : "max-w-sm"} xl:w-xl lg:w-xl md:w-xl sm:w-xl xs:w-11/12 inset-y-10 mx-auto rounded-2xl overflow-auto`}
            overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
        >
            <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(verifyTOTPCode)}>
                <div className="flex flex-1 flex-col w-full">
                    {/*Title with exit button*/}
                    <div className="flex flex-row justify-between items-center">
                        <h1 className="font-bold text-3xl">{isSetup ? "Setup your MFA" : "Enter your MFA"}</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                onClose()
                            }}
                        />
                    </div>
                    <hr className="mt-3 h-0.5" />
                    {/*Form content*/}
                    {qrCode && (
                        <div className="w-full flex flex-1 items-center flex-col justify-center">
                            <small className="leading-6">
                                Here are the next steps, to fully secure your Cyber Crucible
                                account: <br />
                                1. Install Google Authenticator on your phone{" "}
                                <a
                                    className="text-color-orange-100"
                                    href="https://support.google.com/accounts/answer/1066447?co=GENIE.Platform%3DAndroid&hl=en&oco=1"
                                    target="_blank" rel="noreferrer"
                                >
                                    (instructions here)
                                </a>
                                <br />
                                2. Scan the barcode on this screen. <br />
                                3. Enter the code you see on your phone.
                            </small>
                            <QRCode value={qrCode} className="mt-4 self-center" />
                        </div>
                    )}
                    <div className="my-6 flex justify-center items-center">
                        <input
                            autoComplete="off"
                            maxLength={6}
                            minLength={6}
                            name="mfa"
                            type="text"
                            autoFocus={true}
                            required
                            onInput={(e) => {
                                e.target.value = e.target.value.replace(/\D/g,'');
                            }}
                            onKeyPress={(e) => {
                                if(e.key === 'Enter'){
                                    if(document.getElementById("submitCodeButton")){
                                        if(e.target && e.target.value && e.target.value.length === 6){
                                            document.getElementById("submitCodeButton").click()
                                        }
                                    }
                                    e.preventDefault();
                                }}}
                            {...register("mfa")}
                            className="text-center focus:outline-none h-10 p-1 xl:w-50 lg:w-50 md:w-50 sm:w-50 xs:w-full max-w-xs mt-2 rounded-lg border border-black border-opacity-25 border-solid"
                        />
                    </div>
                    <div className="flex justify-center items-center">
                        <ThemeProvider theme={roundButtonTheme}>
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                style={{ fontSize: 20, textTransform: "none", padding: "10px 30px" }}
                                id={"submitCodeButton"}
                                type={"submit"}
                            >
                                Submit Code
                            </Button>
                        </ThemeProvider>
                    </div>

                </div>

            </form>
        </Modal>
    );
};

export const AcceptEulaModal = ({ opened, onClose, onAccept, infoOnly }) => (
    <Modal
        onRequestClose={() => {
            onClose()
        }}
        isOpen={opened}
        contentLabel={"End User License Agreement"}
        className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white h-5/6 xl:w-3/4 lg:w-3/4 md:w-11/12 sm:w-11/12 xs:w-11/12 inset-y-10 mx-auto rounded-2xl overflow-auto`}
        overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
    >
        <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 pb-8">
            {/*Title with exit button*/}
            <div className="flex flex-row justify-center">
                <h1 className="font-bold text-3xl">MSA and Eula V2025.1</h1>
            </div>
            <hr className="mt-3 h-0.5" />
            {/*Form content*/}
            <div className="flex-1 mb-2">
                <small>{masterSaasContent}</small>
                <small>{eulaContent}</small>
            </div>
            <div className="pb-8">
            <ThemeProvider theme={roundButtonTheme}>
            <Button
                variant={"contained"}
                color={"primary"}
                onClick={() => {
                    if(infoOnly){
                        onClose();
                    }
                    else{
                        onAccept()
                    }
                }}>
                {infoOnly ? "Close" : "Accept"}
            </Button>
            </ThemeProvider>
            </div>
        </div>
    </Modal>
);

export const UserInfoModal = ({ onClose, onSuccess, awsUser, opened, onError }) => {
    const { register, handleSubmit} = useForm();
    const updateUserInAws = async (data) => {
        const firstName = data.firstName;
        const lastName = data.lastName;
        const companyName = data.companyName;
        if(firstName.trim().length > 0 && lastName.trim().length > 0){
            //TODO: Make this confirm into a modal, use the <ConfirmationModal>
            let confirmResponse = window.confirm(`You are about to update your account info, would you like to continue?`);
            if(confirmResponse === true) {
                try{
                    await Auth.updateUserAttributes(awsUser, {
                        name: `${firstName.trim()} ${lastName.trim()}`,
                        given_name: firstName.trim(),
                        family_name: lastName.trim()
                    }).then(async () => {
                        //update in mongo
                        await updateAccountInfoReactive(firstName.trim(), lastName.trim(), companyName);
                        onSuccess();
                    }).catch((error) => {
                        onError();
                    })
                } catch (error) {
                    onError();
                }
            }
        }
    }
    return (
        <Modal
            onRequestClose={() => {
                onClose()
            }}
            isOpen={opened}
            contentLabel={"Update Account Info Before Continuing"}
            className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white xl:w-md lg:w-md md:w-md sm:w-md xs:w-11/12 max-w-md inset-y-10 mx-auto rounded-2xl overflow-auto`}
            overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
        >
            <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(updateUserInAws)}>
                <div className="flex flex-1 flex-col w-full">
                    {/*Title with exit button*/}
                    <div className="flex flex-row justify-center">
                        <h1 className="font-bold text-3xl">Update Account Info Before Continuing</h1>
                    </div>
                    <hr className="mt-3 h-0.5" />
                    {/*Form content*/}
                    <div className="ml-1 mt-5 w-full">
                        <small>First Name</small>
                        <br />
                        <input
                            autoComplete="off"
                            name="firstName"
                            type="text"
                            required
                            onKeyPress={(e) => {
                                if(e.key === 'Enter'){
                                    e.preventDefault();
                                }}}
                            {...register("firstName")}
                            className="focus:outline-none h-10 p-1 w-full mt-2 rounded-lg border border-black border-opacity-25 border-solid"
                        />
                    </div>
                    <div className="ml-1 mt-5 w-full">
                        <small>Last Name</small>
                        <br />
                        <input
                            autoComplete="off"
                            name="lastName"
                            type="text"
                            required
                            onKeyPress={(e) => {
                                if(e.key === 'Enter'){
                                    e.preventDefault();
                                }}}
                            {...register("lastName")}
                            className="focus:outline-none h-10 p-1 w-full mt-2 rounded-lg border border-black border-opacity-25 border-solid"
                        />
                    </div>
                    <div className="ml-1 mt-5 w-full">
                        <small>Company Name (Optional)</small>
                        <br />
                        <input
                            autoComplete="off"
                            name="companyName"
                            type="text"
                            onKeyPress={(e) => {
                                if(e.key === 'Enter'){
                                    e.preventDefault();
                                }}}
                            {...register("companyName")}
                            className="focus:outline-none h-10 p-1 w-full mt-2 rounded-lg border border-black border-opacity-25 border-solid"
                        />
                    </div>
                    <div className="flex flex-col mt-5 w-full">

                    <ThemeProvider theme={roundButtonTheme}>
                    <Button type={"submit"} variant={"contained"} color={"primary"}>
                        Update Account
                    </Button>
                    </ThemeProvider>
                    </div>
                </div>

            </form>
        </Modal>
    );
};

export const UserTimeZoneSetupModal = ({ onClose, onSuccess, opened, setIsLoading }) => {
    return (
        <Modal
            onRequestClose={() => {
                onClose()
            }}
            isOpen={opened}
            contentLabel={"Update Default TimeZone Setting Before Continuing"}
            className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white max-w-xl inset-y-10 mx-auto rounded-2xl overflow-auto`}
            overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
        >
            <div className="flex flex-1 flex-col p-5 w-full ml-4 mr-4">
                {/*Title with exit button*/}
                <div className="flex flex-row justify-center">
                    <h1 className="font-bold text-3xl">Update Default TimeZone Setting Before Continuing</h1>
                </div>
                <hr className="mt-3 h-0.5" />
                {/*Form content*/}
                <div className="m-5 flex flex-col gap-y-3">
                    <label>Change Default Timezone Setting</label>
                    <TimezonePicker
                        absolute={false}
                        placeholder="Select timezone..."
                        className="max-w-md w-full rounded-sm border border-black border-opacity-5 border-solid"
                        onChange={async (value) => {
                            if(value){
                                try{
                                    setIsLoading(true)
                                    await changeUserTimeZoneReactive(value)
                                    NotificationManager.success("Default Timezone successfully updated");
                                    encryptAndStoreSessionVariable("timezone", value)
                                    onSuccess()
                                }
                                catch(error){
                                    NotificationManager.error("Error updating your default timezone setting");
                                }
                            }
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
};

export const GroupCollectAgentTelemetrySetupModal = ({ onClose, onSuccess, opened, setIsLoading, groupsToInitialize, onError }) => {
    const [approveAllChecked, setApproveAllChecked] = useState(true);
    const [groupsChecked, setGroupsChecked] = useState([]);

    useEffect(() =>{
        let controller = new AbortController();
        (async () => {
            let defaultState = []
            groupsToInitialize.forEach(zenGroup => {
                defaultState.push(zenGroup.zenGroupId)
            })
            setGroupsChecked(defaultState)
        })()
        return () => controller?.abort();
    }, [groupsToInitialize]);

    async function submit(){
        let groupIdsOffList = []
        //Gather group ids that we are going to turn this setting off for, we know which ones are on because of the groupsChecked list
        groupsToInitialize.forEach(group => {
            if(!groupsChecked.includes(group.zenGroupId)){
                //if the groups checked does not include the group.zenGroupId then we are turning this setting off for this group, add its id to groupIdsOffList
                groupIdsOffList.push(group.zenGroupId)
            }
        })

        setIsLoading(true)
        try{
            await initializeGroupsAgentTelemetrySettingReactive(groupsChecked, groupIdsOffList)
            setIsLoading(false)
            onSuccess()
        }
        catch (error){
            setIsLoading(false)
            onError()
        }
    }

    return (
        <Modal
            onRequestClose={() => {
                onClose()
            }}
            isOpen={opened}
            contentLabel={"Initialize Group Settings for Collecting Agent Telemetry Before Continuing"}
            className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white max-w-2xl inset-y-10 mx-auto rounded-2xl overflow-auto`}
            overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
        >
            <div className="flex flex-1 flex-col p-5 w-full ml-4 mr-4 gap-y-3">
                {/*Title with exit button*/}
                <div className="flex flex-row justify-between">
                    <h1 className="font-bold text-3xl text-center"> (Optional) Additional Telemetry Collection </h1>
                    <div>
                        <MuiIconWithTooltip
                            icon={
                                <FontAwesomeIcon
                                    className="ml-1 object-contain"
                                    icon="fa-light fa-circle-info"
                                    size="2xl"
                                />
                            }
                            tooltipTitle={"We have created a new group setting to enable additional telemetry collection that is useful for identifying possible new types of attacks or program behaviors."}
                            tooltipPlacement={"bottom-start"}
                        />
                    </div>
                </div>
                <hr className="h-0.5" />
                {/*Form content*/}
                <div className={`flex flex-row items-center ml-1 mb-1`}>
                    <ThemeProvider theme = {switchTheme}>
                        <FormControlLabel control={
                            <Switch
                                checked={approveAllChecked}
                                name="selectAllGroupsToggle"
                                onChange={e => {
                                    setApproveAllChecked(e.target.checked)
                                    if(e.target.checked){
                                        //set all toggles to true/on
                                        let allGroupIds = []
                                        groupsToInitialize.forEach(group => {
                                            allGroupIds.push(group.zenGroupId)
                                        })
                                        setGroupsChecked(allGroupIds)
                                    }
                                    else{
                                        //set all toggles to false/off
                                        setGroupsChecked([])
                                    }
                                }}
                            />
                        } label={approveAllChecked ? "Approve All" : "Decline All"}/>
                    </ThemeProvider>
                </div>
                <hr className="h-0.5" />
                {groupsToInitialize && groupsToInitialize.map((group) => {
                    return (
                    <div key={group.zenGroupId} className={`flex flex-row items-center ml-1 mb-1`}>
                        <ThemeProvider theme = {switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    value={group.zenGroupId}
                                    checked={groupsChecked.includes(group.zenGroupId)} //default to true
                                    name={`initializeToggle${group.zenGroupId}`}
                                    onChange={(e) => {
                                        let newSetting = e.target.checked
                                        if(newSetting){
                                            //should be checked
                                            if(!groupsChecked.includes(group.zenGroupId)){
                                                setGroupsChecked(groupCheckedBefore => [...groupCheckedBefore, group.zenGroupId])
                                            }
                                        }
                                        else{
                                            //should not be checked
                                            setGroupsChecked((current) =>
                                                current.filter((groupId) => groupId !== group.zenGroupId)
                                            );
                                        }
                                    }}
                                />
                            } label={group.name}/>
                        </ThemeProvider>
                    </div>
                    );
                })}

                <ThemeProvider theme={buttonTheme}>
                    <Button variant={"contained"} color={"primary"} type={"submit"} onClick={submit}>
                        Submit
                    </Button>
                </ThemeProvider>
            </div>
        </Modal>
    );
};



export const ModuleTitle = ({
                                title,
                                borderless = true,
                                color = "blue",textSize="2xl",bold=""
                            }) => (
    <div
        className={`flex border-l-0 text-left text-${textSize} ${bold}`}
    >
        {title}
    </div>
);

export const Paragraph = ({ children, align = "left", mb="0" }) => (
    <div className={`text-${align} mb-${mb} font-slab font-light text-xl leading-10`}>{children}</div>
);

export const CustomOL = ({ children}) => (
    <ol className={"masterSaasOL ml-5"}>{children}</ol>
);
export const CustomLI = ({ children}) => (
    <li className={"masterSaasLI"}>&nbsp;&nbsp;&nbsp;{children}</li>
);

export const masterSaasContent = (
    <div className="flex flex-col items-start mt-6 gap-y-2">
        <div className={"self-center"}>
            <ModuleTitle title="MASTER SOFTWARE AS A SERVICES AGREEMENT" bold={"font-bold"}/>
            <br/>
        </div>
        <Paragraph>
            This MASTER SOFTWARE-AS-A-SERVICES AGREEMENT (the “Agreement”) describes the terms and conditions pursuant to which Cyber Crucible shall make available the Services (as defined below) to Customer.  Each of Cyber Crucible, possible authorized Cyber Crucible resellers, possible authorized Cyber Crucible distributors,  and the Customer may be referred to herein as a “Party” or collectively the “Parties.
        </Paragraph>
        <Paragraph>
            NOW, THEREFORE, in consideration of mutual promises hereinafter set forth, it is agreed by and between the Parties as follows:
        </Paragraph>
        <Paragraph>
            <CustomOL>
                <CustomLI>SCOPE AND DEFINITIONS.  This Agreement consists of these terms and conditions, the Cyber Crucible End User License Agreement Exhibit A (“EULA”), and all schedules attached and/or referenced thereto, which are incorporated herein by reference.  Unless otherwise defined in this Section 1, the capitalized terms used in this Agreement shall be defined in the context in which they are used.
                <CustomOL>
                    <CustomLI>“Agreement” has the meaning given such term in the preamble.</CustomLI>
                    <CustomLI>“Authorized User(s)” means the Customer’s employees, consultants, contractors, and managed outsourcers who are permitted access to the Intrusion Detection Services hereunder.</CustomLI>
                    <CustomLI>“Confidential Information” means: any information or data (including information or data received by the disclosing Party from a third party and as to which the disclosing Party has confidentiality obligations) provided or disclosed by disclosing Party or its agents to receiving Party that is:
                        <CustomOL>
                            <CustomLI>fixed in a tangible medium and marked as the confidential or proprietary information of the disclosing Party; or</CustomLI>
                            <CustomLI>otherwise provided or disclosed by or on behalf of the disclosing Party marked as proprietary or confidential at the time the information is provided; or</CustomLI>
                            <CustomLI>not falling within any of the prior clauses of this sentence, but which, a reasonable person would conclude is of a confidential nature given the facts and circumstances of such disclosure; or</CustomLI>
                            <CustomLI>the Intrusion Detection Services and the Documentation. The terms of this Agreement shall constitute the Confidential Information of both Parties.
                            </CustomLI>
                        </CustomOL>
                    </CustomLI>
                    <CustomLI>“Customer Data” means Customer’s telemetry, alerts, user information, and group information that are uploaded, stored, analyzed and made available to and through the Intrusion Detection Services.
                    </CustomLI>
                    <CustomLI>“Cyber Crucible Technology” means
                        <CustomOL>
                            <CustomLI>Cyber Crucible’s proprietary Rogue Process Prevention software</CustomLI>
                            <CustomLI>Cyber Crucible’s Intelligence Platform</CustomLI>
                            <CustomLI>Cyber Crucible’s Digital Identity Theft Detection Services</CustomLI>
                        </CustomOL>
                    </CustomLI>
                    <CustomLI>“Digital Identity Theft Detection Services” means Cyber Crucible’s proprietary data analytics and monitoring for stolen passwords, keys, cookies, tokens, cryptowallets, and other data designed to confirm or express the identity of a user.  Monitoring for stolen materials is conducted on a per account or per domain basis.</CustomLI>
                    <CustomLI>“Documentation” means the documentation provided by Cyber Crucible relating to the Rogue Process Prevention Services.
                    </CustomLI>
                    <CustomLI>“Implementation Services” means the installation, configuration and/or training services as specified in an Order Form.
                    </CustomLI>
                    <CustomLI>“Intellectual Property Rights” means all trade secrets, patents and patent applications, trade marks (whether registered or unregistered and including any goodwill acquired in such trade marks), service marks, trade names, business names, internet domain names, e-mail address names, copyrights (including rights in computer software), moral rights, database rights, design rights, rights in know-how, rights in confidential information, rights in inventions (whether patentable or not) and all other intellectual property and proprietary rights (whether registered or unregistered, and any application for the foregoing), and all other equivalent or similar rights which may subsist anywhere in the world.
                    </CustomLI>
                    <CustomLI>“Intelligence Platform” means the combination of servers, databases, and web applications to provide the Customer with Security Intelligence, management of software, management of users, management of groups, Rogue Process Prevention state and activity, and root cause analysis of potential cybersecurity incidents.
                    </CustomLI>
                    <CustomLI>“Intrusion Detection Services” means, collectively or individually, any combination of
                        <CustomOL>
                            <CustomLI>Cyber Crucible Technology</CustomLI>
                            <CustomLI>Cyber Crucible’s Implementation Services</CustomLI>
                            <CustomLI>Cyber Crucible Documentation</CustomLI>
                        </CustomOL>
                    </CustomLI>
                    <CustomLI>“Order Form,” synonymous with Quote or Invoice, means a document signed by both Parties whereby the Customer orders Cyber Crucible Intrusion Detection Services, or any other products or services to be offered by Cyber Crucible pursuant to this Agreement.</CustomLI>
                    <CustomLI>“Personal Data” shall mean any information or data that alone or together with any other information relates to an identified or identifiable natural person or data considered to be personal data as defined under applicable law.</CustomLI>
                    <CustomLI>“Report” means the automated reporting generated by the Intelligence Platform that presents the Security Intelligence.</CustomLI>
                    <CustomLI>“Rogue Process Prevention” means Cyber Crucible’s proprietary Rogue Process Prevention software installed on Customer systems which may upload telemetry and notification of automated responses for further analysis by the Cyber Crucible Technology in order to generate Confidential Information for Customer viewing via the Intelligence Platform. Any patches, updates, upgrades, improvements, additions and other modifications or revised versions that may be made available by Cyber Crucible or its licensors from time to time.</CustomLI>
                    <CustomLI>“Security Intelligence” means the key attributes, characteristics and dynamic behavior of malicious activity learned from alerts and telemetry submitted to the Security Intelligence, including without limitation, such other characteristics such as its structure, sequence, and organization, and vulnerabilities or end points to be exploited, which Security Intelligence is presented in a Report.</CustomLI>
                    <CustomLI>“Services” means collectively or individually, the Intrusion Detection Services or any other products or services to be offered by Cyber Crucible pursuant to this Agreement.</CustomLI>
                    <CustomLI>“Subscription Term” shall have the meaning given such term in the Order Form.</CustomLI>
                    <CustomLI>“Term” has the meaning given in Section 8.1.</CustomLI>
                    <CustomLI>“Use Restrictions” means any use restriction that is specifically agreed to in an Order Form, which may include maximum concurrent active Rogue Process Prevention software licenses per type of installation.</CustomLI>
                </CustomOL>
                </CustomLI>
                <CustomLI>ORDERS FOR INTRUSION DETECTION SERVICES.
                    <CustomOL>
                        <CustomLI>Order Forms.  During the Term, the Parties may execute one or more Order Forms specifying the maximum number of active Rogue Process Prevention software licenses, domains to monitor with Cyber Crucible Digital Identity Theft Monitoring Services, and for the purchase of certain Implementation Services.  The Parties shall negotiate and sign each Order Form separately.  Each Order Form shall set out a description and fee schedule of the Intrusion Detection Services (including specific deliverables and estimated time schedule, if any), maximum number and duration of active software installations, payment terms and any other additional terms that are agreed to by the Parties.  Each Order Form shall be attached to this Agreement and incorporated in this Agreement by reference.  In the event of any conflict between the provisions of this Agreement and the terms of any Order Form(s), the conflict shall be resolved in the following order of priority of interpretation: (a) the Order Form(s); and (b) this Agreement.</CustomLI>
                    </CustomOL>
                </CustomLI>
                <CustomLI>ACCESS TO INTELLIGENCE PLATFORM.
                    <CustomOL>
                        <CustomLI>Grant of Access. Subject to the terms and conditions of this Agreement, during the Subscription Term, Cyber Crucible grants Customer a limited non-exclusive, non-transferable right to:
                            <CustomOL>
                                <CustomLI>access the Intrusion Detection Services to process and analyze Confidential Data in accordance with the Documentation in order to generate the Security Intelligence and the Reports; and</CustomLI>
                                <CustomLI>use and copy the Reports solely for Customer’s internal security business purposes, in each and every case, solely in accordance with applicable Documentation and Use Limitations.  Cyber Crucible reserves the right to modify the Intrusion Detection Services at any time.  Customer understands that this Agreement grants certain rights of access only, and that nothing in this Agreement may be interpreted to require delivery of a copy of any of the software or installation of a copy of such software upon Customer’s computers or systems.</CustomLI>
                            </CustomOL>
                        </CustomLI>
                        <CustomLI>Connectivity.  Cyber Crucible will be responsible for maintaining  connectivity from its Intelligence Platform to the Internet.  Customer is responsible for providing connectivity of the Rogue Process Prevention installed on Customer equipment to the Internet for itself, and Internet connectivity to the Intelligence Platform for its Authorized Users. Customer shall also be responsible for ensuring that latency and available bandwidth from the Customer’s equipment to Cyber Crucible’s Intelligence Platform is adequate to meet Customer’s desired level of performance. If Customer requires a VPN or private network connection to the Rogue Process Prevention Services, Customer is responsible for all costs associated with any specialized network connectivity required by Customer.</CustomLI>
                        <CustomLI>Authorized Users.  Customer is solely responsible for creating individual Authorized User accounts in order to allow access to the Intrusion Detection Services and Documentation, possibly up to the maximum number of Authorized Users if indicated on the Order Form, provided that the access to the Intrusion Detection Services and Documentation by such Authorized User(s) is solely on behalf of and for the benefit of the Customer.  Customer shall not authorize access to or permit use of the Intrusion Detection Services, or Documentation by persons other than Authorized Users.  Customer shall be responsible for all acts and omissions of such Authorized Users and any act or omission by any Authorized User which, if undertaken by the Customer, shall be deemed a breach of this Agreement.  Customer shall make all Authorized Users aware of the provisions of this Agreement as applicable to their access to the Intrusion Detection Services and Documentation and shall cause all Authorized Users to comply with such provisions.  Customer is responsible for all activities that occur under its Authorized User accounts, regardless of whether the activities are authorized by Customer or undertaken by Customer, its employees or any third party, and Cyber Crucible and its affiliates are not responsible for unauthorized access to any Customer account.</CustomLI>
                    </CustomOL>
                </CustomLI>
                <CustomLI>CUSTOMER RESPONSIBILITIES.
                    <CustomOL>
                        <CustomLI>Direct Use. The Rogue Process Prevention Services that may be provided by Cyber Crucible pursuant to this Agreement and an applicable Order Form shall be solely for Customer’s internal business purposes and not for the benefit of any third party without written approval by Cyber Crucible, Inc.  In connection with Customer’s use of the Intrusion Detection Services, Customer shall:
                            <CustomOL>
                                <CustomLI>comply with all applicable laws, court orders, rules and regulations;</CustomLI>
                                <CustomLI>comply with applicable Cyber Crucible End User License Agreement (“EULA”) for access to and use of the Intrusion Detection Services, including but not limited to, its acceptable use policy;</CustomLI>
                                <CustomLI>use reasonable security precautions for providing access to the Intrusion Detection Services by Authorized Users;</CustomLI>
                                <CustomLI>cooperate with Cyber Crucible’s investigation of outages, security problems, unauthorized use of the Intrusion Detection Services and/or any suspected breach of this Agreement, the acceptable use policy, or any applicable law, court order, rule or regulation; and</CustomLI>
                                <CustomLI>promptly notify Cyber Crucible of any known or suspected unauthorized use of Customer’s account, the Intrusion Detection Services or any other breach of security.</CustomLI>
                            </CustomOL>
                        </CustomLI>
                        <CustomLI>General Restrictions.  Customer shall not, and shall ensure that its Authorized Users shall not, take any one, combination of, or all of the following actions:
                            <CustomOL>
                                <CustomLI>use the Services, or Documentation in any manner inconsistent with the Documentation (and the rights expressly granted hereunder), or with applicable laws and government regulations (including without limitation, anti-spam and unsolicited email laws); </CustomLI>
                                <CustomLI>use the Intrusion Detection Services to store or transmit infringing, libelous, obscene, threatening, or otherwise unlawful or tortious material, including without limitation material harmful to children or violating third party intellectual property or privacy rights;</CustomLI>
                                <CustomLI>assign, sublicense, sell, lease or otherwise transfer or convey, or pledge as security or otherwise encumber its rights under this Agreement;</CustomLI>
                                <CustomLI>modify or create any derivative works of the Intrusion Detection Services, or Documentation, except with the prior written consent of Cyber Crucible;</CustomLI>
                                <CustomLI>decompile, disassemble, reverse engineer or otherwise attempt to obtain or perceive the source code from any component of the Intrusion Detection Services;</CustomLI>
                                <CustomLI>use the Intrusion Detection Services to store or transmit viruses, worms, time bombs, Trojan horses and other harmful or malicious code, files, scripts, agents or programs; or</CustomLI>
                                <CustomLI>interfere with or disrupt the integrity or performance of the Intrusion Detection Services.</CustomLI>
                            </CustomOL>
                        </CustomLI>
                        <CustomLI>No Implied Licenses.  Customer acknowledges that there are no licenses granted by implication under this Agreement.  Cyber Crucible retains and reserves all right, title and interest in and to the Services, the Documentation and all Intellectual Property Rights therein.  Customer acknowledges that, as between the Parties, Cyber Crucible owns all Intellectual Property Rights and proprietary interests that are embodied in, or practiced by, the Services, and the Documentation.  Any license granted by Cyber Crucible pursuant to this Agreement is only for Intellectual Property Rights that are owned by Cyber Crucible or that Cyber Crucible has a right to sublicense.  </CustomLI>
                        <CustomLI>No Source Code.  Customer acknowledges the rights granted under this Agreement with respect to the Intrusion Detection Services are intended to apply only to the compiled, object code format of the software, and are not intended as licenses to obtain or use any source code.</CustomLI>
                        <CustomLI>Branding.  Customer shall not delete, alter, cover, or distort any copyright, trademark, any printed or on-screen proprietary or legal notice, or other proprietary rights notice placed by Cyber Crucible on or in the Intrusion Detection Services and Documentation.</CustomLI>
                        <CustomLI>Credentials.  Customer and its Authorized Users shall be responsible for maintaining the confidentiality and security of all passwords and other access protocols required in order to access the Intrusion Detection Services, if applicable.  </CustomLI>
                    </CustomOL>
                </CustomLI>
                <CustomLI>CUSTOMER DATA; SECURITY; PRIVACY/DATA PROTECTION.
                    <CustomOL>
                        <CustomLI>Rights to Customer Data.  Prior to storing, processing, uploading, distributing or linking to any Customer Data using the Intrusion Detection Services, Customer shall, at its own expense, obtain all third-party consents and/or permissions that may be necessary and appropriate with respect to such Customer Data or required by this Section 5.1.  Cyber Crucible will not access or use any Customer Data except with the Customer’s prior written consent, as necessary to maintain or provide the Intrusion Detection Services, or as necessary to comply with the law or a binding order of a governmental body, provided however, that Cyber Crucible will use data related to the Customer’s account, such as resource identifiers, metadata tags, security and access roles, rules, usage policies, permissions, usage statistics and analytics in connection with providing the Intrusion Detection Services.  Customer further agrees to allow Cyber Crucible to copy, store, process, analyze and display such Customer Data through the Intrusion Detection Services.  Customer hereby grants to Cyber Crucible a non-exclusive, non-transferable right and license to use the Customer Data during the Term for the limited purposes of performing Cyber Crucible’s obligations under this Agreement and to collect and use any such data, in non-user specific and aggregated statistical form, for the development and maintenance of the Cyber Crucible products or Services and for Cyber Crucible’s other business purposes.  Customer hereby represents and warrants that it owns or otherwise has sufficient rights to grant Cyber Crucible access to and use of the Customer Data in accordance with the terms of this Agreement.  Cyber Crucible will comply with all applicable US Federal and US state legislation with respect to the storage, use and protection of Personal Data.</CustomLI>
                        <CustomLI>Security.  Cyber Crucible uses third-party data centers located in the United States in order to provide the Intrusion Detection Services, and Customer hereby consents to the storage of any Customer Data in the United States.  Cyber Crucible shall maintain appropriate administrative, physical, and technical safeguards for protection of the security, confidentiality, and integrity of the Intrusion Detection Services, and agrees to implement reasonable and appropriate measures designed to help Customer secure the Customer Data against accidental or unlawful loss, access or disclosure.  Cyber Crucible shall neither house nor allow any service provider to house any Customer Data outside of the United States of America.
                            <CustomOL>
                                <CustomLI>Customer has primary access and control over its dedicated portion of the Intrusion Detection Services.   Customer is responsible for properly configuring and using the Intrusion Detection Services and otherwise taking appropriate action to secure, protect and backup its Authorized User accounts and the Customer Data in a manner that will provide appropriate security and protection, which might include use of encryption to protect Customer Data from unauthorized access and routinely archiving Customer Data.  Customer shall be solely responsible for, and assumes the risk of, any problems resulting from, the content, accuracy, completeness, consistency integrity, legality, reliability, and appropriateness of all Customer Data. Cyber Crucible will conduct backups daily and retain the backups for 7 days to permit recovery of Intrusion Detection Services after a disaster or catastrophic failure.  Cyber Crucible does not have the capability to restore prior data values for a specific customer.  Customer will ensure that the Customer Data and its and the Authorized Users’ use of Customer Data or the Intrusion Detection Services will not violate any of Cyber Crucible acceptable use or privacy policies or any applicable law.  </CustomLI>
                                <CustomLI>Customer shall be the data controller and Cyber Crucible shall be a data processor with respect to any Customer Data processed via the Intrusion Detection Service.  Cyber Crucible shall process Customer Data via the Intrusion Detection Service on behalf of Customer only in accordance with the terms of this Agreement and any instructions reasonably given by Customer from time to time.</CustomLI>
                            </CustomOL>
                        </CustomLI>
                    </CustomOL>
                </CustomLI>
                <CustomLI>OTHER SERVICES.
                    <CustomOL>
                        <CustomLI>Implementation Services.  Customer understands and acknowledges that access to the Intrusion Detection Services by the Customer may require Implementation Services, including, by way of example, configuration or customization, training of Customer personnel, or provision of technical support. All Implementation Services that the Customer may choose to order shall be set forth in an Order Form.</CustomLI>
                        <CustomLI>Other Professional Services.  Customer may elect to order additional professional services from Cyber Crucible on a time and materials basis.  All such professional services will be set forth in an Order Form and will be billed at then-current hourly rates.</CustomLI>
                    </CustomOL>
                </CustomLI>
                <CustomLI>FEES AND EXPENSES; PAYMENTS.
                    <CustomOL>
                        <CustomLI>Fees.  In consideration for the rights granted to Customer and the Intrusion Detection Services made available by Cyber Crucible under this Agreement, Customer will pay to Cyber Crucible, an authorized Cyber Crucible distributor, or an authorized Cyber Crucible reseller, without offset or deduction, all fees required by an Order Form.  Cyber Crucible, an authorized Cyber Crucible distributor, or an authorized Cyber Crucible reseller will submit invoices to Customer with respect to such fees according to the relevant payment schedules indicated on the applicable Order Form, and each invoiced amount will be due and payable upon receipt of the relevant invoice by Customer.  Notwithstanding the foregoing, any initial fees listed on a particular Order Form shall be due and payable upon execution of the applicable Order Form, without additional invoice.  All amounts due hereunder shall be paid in the U.S. and in U.S. dollars.</CustomLI>
                        <CustomLI>Default Payment Terms.  Unless otherwise specified in an Order Form, default payment terms shall be 30 day (NET 30) payment terms.</CustomLI>
                        <CustomLI>Expenses.  Out-of-pocket expenses, including reasonable expenses incurred for non-local travel of Cyber Crucible personnel in connection with this Agreement, will be invoiced to Customer monthly, provided that they shall only be payable if incurred in accordance with Customer’s expense reimbursement policy, and authorized in advance.  </CustomLI>
                        <CustomLI>Taxes.  All fees under this Agreement are net of sales, use and other taxes and all applicable export and import fees, customs duties and similar charges (other than taxes based on Cyber Crucible’s income) (“Taxes”).  Customer will be responsible for payment of any applicable Taxes and any related penalties and interest for the rights hereunder, or the delivery of related services.  Customer will make all required payments to Cyber Crucible, an authorized Cyber Crucible reseller, or an authorized Cyber Crucible distributor  free and clear of, and without reduction for, any withholding taxes.  Any such taxes imposed on payments to Cyber Crucible, an authorized Cyber Crucible reseller, or an authorized Cyber Crucible distributor will be Customer’s sole responsibility, and Customer will, upon Cyber Crucible’s, the authorized Cyber Crucible reseller’s, or the authorized Cyber Crucible distributor’s request, provide the requesting organization with official receipts issued by appropriate taxing authorities, or such other evidence as the requesting party may reasonably request, to establish that such taxes have been paid.</CustomLI>
                        <CustomLI>Late Payments; Interest.  Any portion of any amount payable hereunder that is not paid when due will accrue interest at one and one-half percent (1.5%) per month or the maximum rate permitted by applicable law, whichever is less, from a date thirty (30) days after the due date until paid.</CustomLI>
                        <CustomLI>Suspension of Service. If any charge owing by Customer is 30 days or more overdue, Cyber Crucible may, without limiting its other rights and remedies, suspend Services until such amounts are paid in full, provided Cyber Crucible has given Customer 10 or more days’ prior notice that its account is overdue in accordance with the “Notices” section below.</CustomLI>
                    </CustomOL>
                </CustomLI>
                <CustomLI>TERM AND TERMINATION.
                    <CustomOL>
                        <CustomLI>Term.  The term of this Agreement will commence on the Effective Date annotated on the Order Form and will continue for the period annotated on the Order Form thereafter, unless earlier terminated in accordance with this Section 8 (the “Initial Term”), and can be renewed for additional terms upon mutual agreement.</CustomLI>
                        <CustomLI>Termination for Breach.  Either Party may, at its option, terminate this Agreement in the event of a material breach by the other Party.  Such termination may be effected only through a written notice to the breaching Party, specifically identifying the breach or breaches on which such notice of termination is based.  The breaching Party will have a right to cure such breach or breaches within thirty (30) days of receipt of such notice, if possible, and this Agreement will terminate in the event that such cure is not made within such thirty (30)-day period.  Notwithstanding the foregoing, breaches of confidentiality are not subject to cure, but can be subject to prompt mitigation or remediation.  Nothing in this subparagraph should be construed as limiting the right to suspend services as set forth above.</CustomLI>
                        <CustomLI>Termination Upon Bankruptcy or Insolvency.  Either Party may, at its option, terminate this Agreement immediately upon written notice to the other, in the event (i) that such other Party files for bankruptcy or becomes insolvent or unable to pay its debts when due; (ii) the other Party discontinues it business; or (iii) a receiver is appointed or there is an assignment for the benefit of such other Party’s creditors.</CustomLI>
                        <CustomLI>Effect of Termination.  Upon any termination of this Agreement, Customer will (a) immediately discontinue all use of the Rogue Process Prevention Services, and any Cyber Crucible Confidential Information; and (b) promptly pay to Cyber Crucible all amounts due and payable under this Agreement.</CustomLI>
                        <CustomLI>Survival.  The provisions of Sections 5, 7, 8.4, 8.5, 9, 11, 12, and 13 will survive the termination of this Agreement.</CustomLI>
                    </CustomOL>
                </CustomLI>
                <CustomLI>TREATMENT OF CONFIDENTIAL INFORMATION.
                    <CustomOL>
                        <CustomLI>Ownership of Confidential Information.  The Parties acknowledge that during the performance of this Agreement, each Party will have access to certain of the other Party’s Confidential Information or Confidential Information of third parties that the disclosing Party is required to maintain as confidential.  Both Parties agree that all items of Confidential Information are proprietary to the disclosing Party or such third party, as applicable, and will remain the sole property of the disclosing Party or such third party.</CustomLI>
                        <CustomLI>Mutual Confidentiality Obligations.  Each Party agrees as follows:
                            <CustomOL>
                                <CustomLI>to use Confidential Information disclosed by the other Party only for the purposes described herein;</CustomLI>
                                <CustomLI>that such Party will not reproduce Confidential Information disclosed by the other Party, and will hold in confidence and protect such Confidential Information from dissemination to, and use by, any third party;</CustomLI>
                                <CustomLI>that neither Party will create any derivative work from Confidential Information disclosed to such Party by the other Party;</CustomLI>
                                <CustomLI>to restrict access to the Confidential Information disclosed by the other Party to such of its personnel, agents, and/or consultants, if any, who have a need to have access and who have been advised of and have agreed in writing to treat such information in accordance with the terms of this Agreement; and</CustomLI>
                                <CustomLI>to the extent practicable, return or destroy, all Confidential Information disclosed by the other Party that is in its possession upon termination or expiration of this Agreement.  </CustomLI>
                                <CustomLI>Notwithstanding the foregoing, Customer agrees that Cyber Crucible may collect aggregated statistical data regarding Customer’s use of the Services and provide such aggregated statistical data to third parties.  In no event shall Cyber Crucible provide to third parties specific data regarding Customer or Customer’s authorized users.</CustomLI>
                            </CustomOL>
                        </CustomLI>
                        <CustomLI>Confidentiality Exceptions.  Notwithstanding the foregoing, the provisions of Sections 9.1 and 9.2 will not apply to Confidential Information that
                            <CustomOL>
                                <CustomLI>is publicly available or in the public domain at the time disclosed; or</CustomLI>
                                <CustomLI>is or becomes publicly available or enters the public domain through no fault of the recipient; or</CustomLI>
                                <CustomLI>is rightfully communicated to the recipient by persons not bound by confidentiality obligations with respect thereto; or</CustomLI>
                                <CustomLI>is already in the recipient’s possession free of any confidentiality obligations with respect thereto at the time of disclosure; or</CustomLI>
                                <CustomLI>is independently developed by the recipient; or</CustomLI>
                                <CustomLI>is approved for release or disclosure by the disclosing Party without restriction.</CustomLI>
                            </CustomOL>
                        </CustomLI>
                        <CustomLI>
                            Judicial or Administrative Orders.  Notwithstanding the foregoing, each Party may disclose Confidential Information to the limited extent required (x) in order to comply with the order of a court or other governmental body, or as otherwise necessary to comply with applicable law, provided that the Party making the disclosure pursuant to the order shall first have given written notice to the other Party (unless such court order specifically requires non-disclosure) and allow the other Party with a reasonable opportunity to obtain a protective order; or (y) to establish a Party’s rights under this Agreement, including to make such court filings as it may be required to do.
                        </CustomLI>
                        <CustomLI>Limitation Period.  The obligations set forth in this Section 9 shall survive the termination or expiration of this Agreement for a period of two (2) years.
                        </CustomLI>
                    </CustomOL>
                </CustomLI>
                <CustomLI>REPRESENTATIONS AND WARRANTIES.
                    <CustomOL>
                        <CustomLI>Each Party hereby represents and warrants
                            <CustomOL>
                                <CustomLI>that it is duly organized, validly existing and in good standing under the laws of its jurisdiction of incorporation or organization;</CustomLI>
                                <CustomLI>that the execution and performance of this Agreement will not conflict with or violate any provision of any law having applicability to such Party; and</CustomLI>
                                <CustomLI>that this Agreement, when executed and delivered, will constitute a valid and binding obligation of such Party and will be enforceable against such Party in accordance with its terms.</CustomLI>
                            </CustomOL>
                        </CustomLI>
                        <CustomLI>Warranty.  All products supplied to Customer by Cyber Crucible, Inc., under this Agreement shall:
                            <CustomOL>
                                <CustomLI>be conveyed with clear title, free of claims of any nature by any third person;</CustomLI>
                                <CustomLI>be of merchantable quality;</CustomLI>
                                <CustomLI>be fit for the purpose for which they are purchased;</CustomLI>
                                <CustomLI>be in compliance with applicable laws, rules and regulations, including but not limited to all industry standards;</CustomLI>
                                <CustomLI>be manufactured and provided in strict accordance with industry standard quality assurance validation;</CustomLI>
                                <CustomLI>not infringe the intellectual property rights of any third party; and</CustomLI>
                                <CustomLI>be governed by the rights and limitations found in the Cyber Crucible End User License Agreement</CustomLI>
                            </CustomOL>
                        </CustomLI>
                        <CustomLI>Disclaimer.  EXCEPT AS EXPRESSLY REPRESENTED OR WARRANTED IN THIS SECTION 10, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE ROGUE PROCESS PREVENTION SERVICE, THE DOCUMENTATION, AND ALL SERVICES PERFORMED BY CYBER CRUCIBLE ARE PROVIDED “AS IS,” AND CYBER CRUCIBLE DISCLAIMS ANY AND ALL OTHER PROMISES, REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT, SYSTEM INTEGRATION AND/OR DATA ACCURACY.  CYBER CRUCIBLE DOES NOT WARRANT THAT THE ROGUE PROCESS PREVENTION SERVICES, OR ANY OTHER SERVICES PROVIDED BY CYBER CRUCIBLE WILL MEET CUSTOMER’S REQUIREMENTS OR THAT THE OPERATION OF THE ROGUE PROCESS PREVENTION SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT ALL ERRORS WILL BE CORRECTED.</CustomLI>
                    </CustomOL>
                </CustomLI>
                <CustomLI>INDEMNIFICATION.
                    <CustomOL>
                        <CustomLI>Indemnification of Customer.  Cyber Crucible agrees to indemnify, defend and hold harmless Customer from and against any and all losses, liabilities, costs, expenses (including reasonable attorneys’ fees) or damages resulting from any claim by any third party that the authorized use of the Rogue Process Prevention Service, and/or the Documentation infringes such third party’s U.S. patents issued as of the Effective Date, or infringes or misappropriates, as applicable, such third party’s copyrights or trade secret rights under applicable laws of any jurisdiction within the United States of America and shall pay any final judgment awarded against Customer as a result of any such claim, action or allegation.  If such a claim is made or appears possible, Customer agrees to permit Cyber Crucible, at Cyber Crucible’s sole discretion, to:
                            <CustomOL>
                                <CustomLI>enable Customer to continue to use the Cyber Crucible Technology, or the Documentation, as applicable;</CustomLI>
                                <CustomLI>to modify or replace any such infringing material to make it non-infringing; or</CustomLI>
                                <CustomLI>require Customer to cease use of, and, if applicable, return, such materials as are the subject of the infringement claim.  This Section 11.1 shall not apply if the alleged infringement, violation or misappropriation arises, in whole or in part, from:
                                    <CustomOL>
                                        <CustomLI>modification of the Cyber Crucible Technology, or the Documentation by Customer;</CustomLI>
                                        <CustomLI>combination, operation or use of the Cyber Crucible Technology with other software, hardware or technology not provided by Cyber Crucible; or</CustomLI>
                                        <CustomLI>the collection, use, storage, sharing, analysis or distribution of the Customer Data.  This Section states Cyber Crucible’s entire obligation and liability with respect to any claim of infringement, misappropriation, or violation of any Intellectual Property Right.</CustomLI>
                                    </CustomOL>
                                </CustomLI>
                            </CustomOL>
                        </CustomLI>
                        <CustomLI>Customer’s Indemnity Obligations.  Customer agrees to indemnify, defend and hold harmless Cyber Crucible from and against any and all losses, liabilities, costs, expenses (including reasonable attorneys’ fees) or damages resulting from any claim by any third party based upon or arising from claims of infringement, misappropriation or violation of any third-party proprietary right, including copyright, patent, trade secret, right of publicity, right of privacy, and trademark rights, arising from the use, storage, retransmission and analysis of any Customer Data.</CustomLI>
                        <CustomLI>Indemnification Procedures. With respect to any claim, demand or action for which an indemnity is provided under this section, the Party to be indemnified (the “Indemnified Party”) shall:
                            <CustomOL>
                                <CustomLI>give prompt written notice to the indemnifying Party (the “Indemnifying Party”) of the claim, demand or action for which an indemnity is sought (provided, however, that failure of Indemnified Party to provide such notice will not release the Indemnifying Party from any of its indemnity obligations except to the extent that the Indemnifying Party’s ability to defend such claim is prejudiced thereby),</CustomLI>
                                <CustomLI>reasonably cooperate in the defense or settlement of any such claim, demand, or action; and</CustomLI>
                                <CustomLI>give the Indemnifying Party sole control over the defense or settlement of any such claim; provided, however, the Indemnifying Party shall not enter into a settlement agreement without the Indemnified Party’s express consent to
                                    <CustomOL>
                                        <CustomLI>assign, impart or impute fault or responsibility to the Indemnified Party or its affiliates, or</CustomLI>
                                        <CustomLI>include a consent to an injunction or similar relief binding upon the Indemnified Party or its affiliates, or</CustomLI>
                                        <CustomLI>fail to contain reasonable confidentiality obligations protecting the confidentiality of the settlement, or</CustomLI>
                                        <CustomLI>provide for relief other than monetary damages that the Indemnifying Party solely bears.</CustomLI>
                                    </CustomOL>
                                </CustomLI>
                            </CustomOL>
                        </CustomLI>
                    </CustomOL>
                </CustomLI>
                <CustomLI>EXCLUSIONS AND LIMITATIONS OF LIABILITY.
                    <CustomOL>
                        <CustomLI>Exclusions of Remedies; Limitation of Liability.  IN NO EVENT WILL EITHER PARTY BE LIABLE FOR ANY INCIDENTAL, INDIRECT, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, REGARDLESS OF THE NATURE OF THE CLAIM, INCLUDING, WITHOUT LIMITATION, LOST PROFITS, COSTS OF DELAY, ANY FAILURE OF DELIVERY, BUSINESS INTERRUPTION, COSTS OF LOST OR DAMAGED DATA OR DOCUMENTATION, OR LIABILITIES TO THIRD PARTIES ARISING FROM ANY SOURCE, EVEN IF A PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  THIS EXCLUSION OF CERTAIN DAMAGES AND CLAIMS IS INTENDED TO APPLY WITHOUT REGARD TO WHETHER OTHER PROVISIONS OF THIS AGREEMENT HAVE BEEN BREACHED OR HAVE PROVEN INEFFECTIVE.  THE CUMULATIVE LIABILITY OF EITHER PARTY FOR ALL CLAIMS ARISING FROM OR RELATING TO THIS AGREEMENT, INCLUDING, WITHOUT LIMITATION, ANY CAUSE OF ACTION SOUNDING IN CONTRACT, TORT, OR STRICT LIABILITY, WILL NOT EXCEED THE TOTAL AMOUNT OF ALL FEES PAID TO CYBER CRUCIBLE BY THE CUSTOMER DURING THE TWELVE (12)-MONTH PERIOD PRIOR TO THE ACT, OMISSION OR OCCURRENCE GIVING RISE TO SUCH LIABILITY.  THIS LIMITATION OF LIABILITY IS INTENDED TO APPLY WITHOUT REGARD TO WHETHER OTHER PROVISIONS OF THIS AGREEMENT HAVE BEEN BREACHED OR HAVE PROVEN INEFFECTIVE.</CustomLI>
                        <CustomLI>Essential Basis of the Agreement.  Customer acknowledges and understands that the disclaimers, exclusions and limitations of liability set forth in this Section 12 form an essential basis of the agreement between the Parties, that the Parties have relied upon such disclaimers, exclusions and limitations of liability in negotiating the terms and conditions in this Agreement, and that absent such disclaimers, exclusions and limitations of liability, the terms and conditions of this Agreement would be substantially different.</CustomLI>
                    </CustomOL>
                </CustomLI>
                <CustomLI>MISCELLANEOUS
                    <CustomOL>
                        <CustomLI>Entire Agreement.  This Agreement, along with any the End User Licensing Agreement attached as Exhibit A, any Order Forms, and Distributor or Reseller Agreement, as applicable, sets forth the entire agreement and understanding between the Parties with respect to the subject matter of this Agreement and, supersedes and merges all prior oral and written agreements, discussions and understandings between the Parties with respect to the subject matter of this Agreement, and neither of the Parties will be bound by any conditions, inducements or representations other than as expressly provided for in this Agreement.</CustomLI>
                        <CustomLI>Independent Contractors.  In making and performing this Agreement, Customer and Cyber Crucible act and will act at all times as independent contractors, and, except as expressly set forth herein, nothing contained in this Agreement will be construed or implied to create an agency, partnership or employer and employee relationship between them.  Except as expressly set forth herein, at no time will either Party make commitments or incur any charges or expenses for, or in the name of, the other Party.</CustomLI>
                        <CustomLI>Notices.  All notices required by or relating to this Agreement will be in writing and will be sent by means of certified mail, postage prepaid, to the Parties at their respective addresses below, or addressed to such other address as the receiving Party may have given by written notice in accordance with this provision.<br/>
                            <div>Cyber Crucible:</div>
                            <div>&nbsp;&nbsp;&nbsp;Cyber Crucible, Inc</div>
                            <div>&nbsp;&nbsp;&nbsp;1378 Freeport Road</div>
                            <div>&nbsp;&nbsp;&nbsp;Suite 1B</div>
                            <div>&nbsp;&nbsp;&nbsp;Pittsburgh, PA 15238</div>
                            <div>&nbsp;&nbsp;&nbsp;Attn: Dennis Underwood</div>
                            <div>&nbsp;&nbsp;&nbsp;Title:  CEO</div>
                            <div>&nbsp;&nbsp;&nbsp;Email: ceo@cybercrucible.com</div>
                        </CustomLI>
                        <CustomLI>All notices required by or relating to this Agreement may also be communicated by facsimile, provided that the sender receives and retains confirmation of successful transmittal to the recipient.  Such notices will be effective on the date indicated in such confirmation.  In the event that either Party delivers any notice by means of facsimile transmission in accordance with the preceding sentence, such Party will promptly thereafter send a duplicate of such notice in writing by means of certified mail, postage prepaid, to the receiving Party, addressed as set forth above or to such other address as the receiving Party may have previously substituted by written notice to the sender.</CustomLI>
                        <CustomLI>Amendments; Modifications.  This Agreement may not be amended or modified except in a writing duly executed by authorized representatives of both Parties.</CustomLI>
                        <CustomLI>Assignment; Delegation.  Customer shall not assign any of its rights or delegate any of its duties under this Agreement without the express, prior written consent of Cyber Crucible, and, absent such consent, any attempted assignment or delegation will be null, void and of no effect.</CustomLI>
                        <CustomLI>No Third Party Beneficiaries.  The Parties acknowledge that the covenants set forth in this Agreement are intended solely for the benefit of the Parties, their successors and permitted assigns.  Nothing herein, whether express or implied, will confer upon any person or entity, other than the Parties, their successors and permitted assigns, any legal or equitable right whatsoever to enforce any provision of this Agreement.</CustomLI>
                        <CustomLI>Severability.  If any provision of this Agreement is invalid or unenforceable for any reason in any jurisdiction, such provision will be construed to have been adjusted to the minimum extent necessary to cure such invalidity or unenforceability.  The invalidity or unenforceability of one or more of the provisions contained in this Agreement will not have the effect of rendering any such provision invalid or unenforceable in any other case, circumstance or jurisdiction, or of rendering any other provisions of this Agreement invalid or unenforceable whatsoever.</CustomLI>
                        <CustomLI>Waiver.  No waiver under this Agreement will be valid or binding unless set forth in writing and duly executed by the Party against whom enforcement of such waiver is sought.  Any such waiver will constitute a waiver only with respect to the specific matter described therein and will in no way impair the rights of the Party granting such waiver in any other respect or at any other time.  Any delay or forbearance by either Party in exercising any right hereunder will not be deemed a waiver of that right.</CustomLI>
                        <CustomLI>Force Majeure.  If a Party is prevented or delayed in performance of its obligations hereunder (excluding payment obligations) as a result of circumstances beyond such Party’s reasonable control, including, by way of example, war, riot, fires, floods, epidemics, or failure of public utilities or public transportation systems, such failure or delay will not be deemed to constitute a material breach of this Agreement, but such obligation will remain in full force and effect, and will be performed or satisfied as soon as reasonably practicable after the termination of the relevant circumstances causing such failure or delay, provided that if such Party is prevented or delayed from performing for more than ninety (90) days, the other Party may terminate this Agreement upon thirty (30) days’ written notice.</CustomLI>
                        <CustomLI>Attorneys’ Fees.  Should Cyber Crucible be required to bring any action to enforce its rights under this Agreement, then in addition to all other remedies available to it, Cyber Crucible is entitled to payment of all costs and attorneys’ fees expended in enforcing its rights under this Agreement.</CustomLI>
                        <CustomLI>Governing Law.  THIS AGREEMENT WILL BE GOVERNED BY AND INTERPRETED IN ACCORDANCE WITH THE LAWS OF THE US STATE OF DELAWARE, WITHOUT REGARD TO CONFLICTS OF LAW PRINCIPLES THEREOF OR TO THE UNITED NATIONS CONVENTION ON THE INTERNATIONAL SALE OF GOODS.  FOR PURPOSES OF ALL CLAIMS BROUGHT UNDER THIS AGREEMENT, EACH OF THE PARTIES HEREBY IRREVOCABLY SUBMITS TO THE EXCLUSIVE JURISDICTION OF THE STATE COURTS LOCATED IN WILMINGTON, DELAWARE AND/OR THE UNITED STATES DISTRICT COURT OF DELAWARE.  To that end, each Party irrevocably consents to the exclusive jurisdiction of, and venue in, such court(s), and waives any,
                            <CustomOL>
                                <CustomLI>objection it may have to any proceedings brought in any such court,</CustomLI>
                                <CustomLI>claim that the proceedings have been brought in an inconvenient forum, and</CustomLI>
                                <CustomLI>right to object (with respect to such proceedings) that such court does not have jurisdiction over such Party.</CustomLI>
                            </CustomOL>
                        </CustomLI>
                        <CustomLI>Without limiting the generality of the foregoing, the Customer consents to the service of process in connection with any such claim or dispute by the mailing thereof by registered or certified mail, postage prepaid to the Customer, at the address for notice set forth in, or designated pursuant to, this Agreement.  To the fullest extent permitted by law, each Party hereby expressly waives (on behalf of itself and on behalf of any person or entity claiming through such Party) any right to a trial by jury in any action, suit, proceeding, or counterclaim of any kind arising out of or in any manner connected with this Agreement or the subject matter hereof.</CustomLI>
                        <CustomLI>U.S. Government End-Users.  The access to the software program and/or documentation shall be deemed “commercial computer software” and is provided to the U.S. Government Agency subject to the limitations set forth in this Agreement.  Notwithstanding, this Software and/or the documentation is provided to the U.S. Government Agency with RESTRICTED RIGHTS and its supporting documentation is provided with LIMITED RIGHTS.  Use, duplication, or disclosure by the United States Government is subject to the restrictions as set forth in FAR52.227-14 and DFARS252.227-7013 et seq. or the successor as appropriate.  The Manufacturer is Cyber Crucible, Inc.</CustomLI>
                    </CustomOL>
                </CustomLI>
                <CustomLI>Counterparts
                    <CustomOL>
                        <CustomLI>This Agreement may be executed in any number of counterparts, each of which when so executed will be deemed to be an original and all of which when taken together will constitute one Agreement.</CustomLI>
                    </CustomOL>
                </CustomLI>
            </CustomOL>

        </Paragraph>
    </div>
)

export const eulaContent = (
    <div className="flex flex-col items-start mt-6">
        <div className={"self-center"}>
            <ModuleTitle title="Exhibit A:" textSize={"2xl"} bold={"font-bold"} />
        </div>
        <div className={"self-center"}>
            <ModuleTitle title="Cyber Crucible End User License Agreement (“EULA”)" textSize={"2xl"} bold={"font-bold"} />
            <br/>
        </div>
        <Paragraph>
            This copy of Cyber Crucible ("the Software Product") and accompanying documentation is licensed and not sold. This Software Product is protected by copyright laws and treaties, as well as laws and treaties related to other forms of intellectual property. Cyber Crucible, Inc. or its subsidiaries, affiliates, and suppliers (collectively "Cyber Crucible") own intellectual property rights in the Software Product. The Licensee's ("you" or "your") license to download, use, copy, or change the Software Product is subject to these rights and to all the terms and conditions of this End User License Agreement ("Agreement").
        </Paragraph>
        <ModuleTitle title="Acceptance" />
        <Paragraph>
            YOU ACCEPT AND AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT BY SELECTING THE "ACCEPT" OPTION AND DOWNLOADING THE SOFTWARE PRODUCT OR BY INSTALLING, USING, OR COPYING THE SOFTWARE PRODUCT. YOU MUST AGREE TO ALL OF THE TERMS OF THIS AGREEMENT BEFORE YOU WILL BE ALLOWED TO DOWNLOAD THE SOFTWARE PRODUCT. IF YOU DO NOT AGREE TO ALL OF THE TERMS OF THIS AGREEMENT, YOU MUST SELECT "DECLINE" AND YOU MUST NOT INSTALL, USE, OR COPY THE SOFTWARE PRODUCT.
        </Paragraph>
        <ModuleTitle title="License Grant" />
        <Paragraph>
            This Agreement entitles you to install and use one copy of the Software Product. In addition, you may make one archival copy of the Software Product. The archival copy must be on a storage medium other than a hard drive, and may only be used for the reinstallation of the Software Product. This Agreement does not permit the installation or use of multiple copies of the Software Product, or the installation of the Software Product on more than one computer at any given time, on a system that allows shared use of applications, on a multi-user network, or on any configuration or system of computers that allows multiple users. Multiple copy use or installation is only allowed if you obtain an appropriate licensing agreement for each user and each copy of the Software Product. For further information regarding multiple copy licensing of the Software Product, please contact:
            <br />
            &nbsp;&nbsp;&nbsp;Address: 1378 Freeport Road, Suite 1B, Pittsburgh, PA 15238
            <br />
            &nbsp;&nbsp;&nbsp;Phone Number: 412.775.2158
            <br />
            &nbsp;&nbsp;&nbsp;E-mail Address: support@cybercrucible.com
        </Paragraph>
        <ModuleTitle title="Restrictions on Transfer" />
        <Paragraph>
            Without first obtaining the express written consent of Cyber Crucible, you may not assign your rights and obligations under this Agreement, or redistribute, encumber, sell, rent, lease, sublicense, or otherwise transfer your rights to the Software Product.
        </Paragraph>
        <ModuleTitle title="Restrictions on Use" />
        <Paragraph>
            You may not use, copy, or install the Software Product on any system with more than one computer, or permit the use, copying, or installation of the Software Product by more than one user or on more than one computer. If you hold multiple, validly licensed copies, you may not use, copy, or install the Software Product on any system with more than the number of computers permitted by license, or permit the use, copying, or installation by more users, or on more computers than the number permitted by license.
            <br />
            <br />
            You may not decompile, "reverse-engineer," disassemble, or otherwise attempt to derive the source code for the Software Product.
            <br />
            <br />
            You may not use the database portion of the Software Product in connection with any software other than the Software Product.
        </Paragraph>
        <ModuleTitle title="Restrictions on Alteration" />
        <Paragraph>
            You may not modify the Software Product or create any derivative work of the Software Product or its accompanying documentation. Derivative works include but are not limited to translations. You may not alter any files or libraries in any portion of the Software Product. You may not reproduce the database portion or create any tables or reports relating to the database portion.
        </Paragraph>
        <ModuleTitle title="Restrictions on Copying" />
        <Paragraph>
            You may not copy any part of the Software Product except to the extent that licensed use inherently demands the creation of a temporary copy stored in computer memory and not permanently affixed on storage medium. You may make one archival copy which must be stored on a medium other than a computer hard drive.
        </Paragraph>
        <ModuleTitle title="Disclaimer of Warranties and Limitation of Liability" />
        <Paragraph>
            UNLESS OTHERWISE EXPLICITLY AGREED TO IN WRITING BY CYBER CRUCIBLE, CYBER CRUCIBLE MAKES NO OTHER WARRANTIES, EXPRESS OR IMPLIED, IN FACT OR IN LAW, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OTHER THAN AS SET FORTH IN THIS AGREEMENT OR IN THE LIMITED WARRANTY DOCUMENTS PROVIDED WITH THE SOFTWARE PRODUCT.
            <br />
            <br />
            Cyber Crucible makes no warranty that the Software Product will meet your requirements or operate under your specific conditions of use. Cyber Crucible makes no warranty that operation of the Software Product will be secure, error free, or free from interruption.
            <br />
            <br />
            YOU MUST DETERMINE WHETHER THE SOFTWARE PRODUCT SUFFICIENTLY MEETS YOUR REQUIREMENTS FOR SECURITY AND UNINTERRUPTABILITY. YOU BEAR SOLE RESPONSIBILITY AND ALL LIABILITY FOR ANY LOSS INCURRED DUE TO FAILURE OF THE SOFTWARE PRODUCT TO MEET YOUR REQUIREMENTS. CYBER CRUCIBLE WILL NOT, UNDER ANY CIRCUMSTANCES, BE RESPONSIBLE OR LIABLE FOR THE LOSS OF DATA ON ANY COMPUTER OR INFORMATION STORAGE DEVICE.
            <br />
            <br />
            UNDER NO CIRCUMSTANCES SHALL CYBER CRUCIBLE, ITS DIRECTORS, OFFICERS, EMPLOYEES OR AGENTS BE LIABLE TO YOU OR ANY OTHER PARTY FOR INDIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL, PUNITIVE, OR EXEMPLARY DAMAGES OF ANY KIND (INCLUDING LOST REVENUES OR PROFITS OR LOSS OF BUSINESS) RESULTING FROM THIS AGREEMENT, OR FROM THE FURNISHING, PERFORMANCE, INSTALLATION, OR USE OF THE SOFTWARE PRODUCT, WHETHER DUE TO A BREACH OF CONTRACT, BREACH OF WARRANTY, OR THE NEGLIGENCE OF CYBER CRUCIBLE OR ANY OTHER PARTY, EVEN IF CYBER CRUCIBLE IS ADVISED BEFOREHAND OF THE POSSIBILITY OF SUCH DAMAGES. TO THE EXTENT THAT THE APPLICABLE JURISDICTION LIMITS CYBER CRUCIBLE'S ABILITY TO DISCLAIM ANY IMPLIED WARRANTIES, THIS DISCLAIMER SHALL BE EFFECTIVE TO THE MAXIMUM EXTENT PERMITTED.
        </Paragraph>
        <ModuleTitle title="Limitation of Remedies and Damages" />
        <Paragraph>
            Your remedy for a breach of this Agreement or of any warranty included in this Agreement is the correction or replacement of the Software Product. Selection of whether to correct or replace shall be solely at the discretion of Cyber Crucible. Cyber Crucible reserves the right to substitute a functionally equivalent copy of the Software Product as a replacement. If Cyber Crucible is unable to provide a replacement or substitute Software Product or corrections to the Software Product, your sole alternate remedy shall be a refund of the purchase price for the Software Product exclusive of any costs for shipping and handling.
            <br />
            <br />
            Any claim must be made within the applicable warranty period. All warranties cover only defects arising under normal use and do not include malfunctions or failure resulting from misuse, abuse, neglect, alteration, problems with electrical power, acts of nature, unusual temperatures or humidity, improper installation, or damage determined by Cyber Crucible to have been caused by you. All limited warranties on the Software Product are granted only to you and are non-transferable. You agree to indemnify and hold Cyber Crucible harmless from all claims, judgments, liabilities, expenses, or costs arising from your breach of this Agreement and/or acts or omissions.
        </Paragraph>
        <ModuleTitle title="Governing Law, Jurisdiction and Costs" />
        <Paragraph>
            This Agreement is governed by the laws of Delaware, without regard to Delaware's conflict or choice of laws provisions.
        </Paragraph>
        <ModuleTitle title="Severability" />
        <Paragraph>
            If any provision of this Agreement shall be held to be invalid or unenforceable, the remainder of this Agreement shall remain in full force and effect. To the extent any express or implied restrictions are not permitted by applicable laws, these express or implied restrictions shall remain in force and effect to the maximum extent permitted by such applicable laws.
        </Paragraph>
    </div>
);